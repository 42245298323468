import { v4 } from "uuid";
import { dateFnsFormat } from "../../../utils/common";

export function cardOptions(user) {
  const options = [
    {
      id: v4(),
      head: "Personal Information",
      children: [
        { id: v4(), subHead: "Full Name", value: user?.firstName + " " + user?.lastName },
        { id: v4(), subHead: "Address", value: user?.address },
        { id: v4(), subHead: "Personal Email", value: user?.personalEmail },
        { id: v4(), subHead: "Emergency Number", value: user?.emergencyContact },
        {
          id: v4(),
          subHead: "D.O.B",
          value: user?.dateOfBirth && dateFnsFormat(user?.dateOfBirth),
        },
      ],
    },

    {
      id: v4(),
      head: "Employee Details",
      children: [
        { id: v4(), subHead: "Employee Number", value: user?.employeeNumber },
        { id: v4(), subHead: "Address", value: user?.workLocation },
        {
          id: v4(),
          subHead: "Contract / Joining Date",
          value: user?.dateOfJoining && dateFnsFormat(user?.dateOfJoining),
        },
        {
          id: v4(),
          subHead: "ESI Number",
          value: user?.esiNumber,
        },
        {
          id: v4(),
          subHead: "PF Number",
          value: user?.pfNumber?.toUpperCase(),
        },
        {
          id: v4(),
          subHead: "Reporting Manager",
          value: user?.reportingManager && (user?.reportingManager?.firstName + " " + user?.reportingManager?.lastName),
        },
        {
          id: v4(),
          subHead: "Department",
          value: user?.departmentName?.toUpperCase(),
        },
      ],
    },

    {
      id: v4(),
      head: "Bank Details",
      children: [
        { id: v4(), subHead: "Bank Name", value: user?.bankName },
        { id: v4(), subHead: "Account Holder Name", value: user?.accountHolderName },
        { id: v4(), subHead: "IFSC", value: user?.ifscCode },
        { id: v4(), subHead: "Bank Account Number", value: user?.bankAccountNumber },
        {
          id: v4(),
          subHead: "PAN (Permanent Account Number)",
          value: user?.pan,
        },
      ],
    },
  ];
  return options;
}
