import React, { useContext, useEffect } from "react";
import TimeSelect from "../../components/common/TimeSelect/TimeSelect";
import { memo } from "react";
import { useStore } from "../../context/createFastContext";
import DatePicker from "../../components/common/DatePicker/DatePicker";
import SelectWithDropdown from "../../components/common/SelectWithDropdown/SelectWithDropdown";
import { GoPlus } from "react-icons/go";
import { debouncedPromiseOptions, getUser } from "../../utils/common";
import { GetUsers } from "../../services/UserService";
import { AuthContext } from "../../context/AuthContext";
import { useLocation } from "react-router";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "../../constants/Constant";
import AsyncSelect from "react-select/async";

const AddAttendanceModalBody = ({ btn_prop, content = null }) => {
  const { user } = useContext(AuthContext);

  let { txt, onClick } = btn_prop; // pass all modal main btn related style inside btn_props object and then destructure it
  let contentRender = <Default />; //  default content to render
  if (content) {
    contentRender = content; // default content replaced with props content render
  }
  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <div className="-mb-10 px-4 mx-auto max-w-2xl lg:py-4 m-auto overflow-x-hidden overflow-y-auto">
          <form>
            {contentRender}
            {/* Button to Update user attendance information */}
            <div className="text-end mb-6">
              <button
                type="submit"
                onClick={e => onClick(e, user?.id)}
                className="tc-btn-dark inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white rounded-lg focus:ring-4">
                <GoPlus className="mr-[8px]" size={"1rem"} /> {txt}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default AddAttendanceModalBody;

/* eslint-disable react/display-name */
const Default = memo(() => {
  const [attendanceState, setStore] = useStore(s => s.attendanceState);
  const { add_modal_timer } = attendanceState;

  const location = useLocation();

  const pathname = location.pathname;

  const isUserSelected = pathname === "/attendance/approvals";

  useEffect(() => {
    setStore({
      attendanceState: {
        ...attendanceState,
        selectedUserModal: {
          label:
            attendanceState?.selectedUser === null
              ? ""
              : attendanceState?.selectedUser?.value?.firstName +
                " " +
                attendanceState?.selectedUser?.value?.lastName,
          value: attendanceState?.selectedUser?.value?.id,
        },
      },
    });
  }, []);

  const options = debouncedPromiseOptions((inputValue, callback) => {
    GetUsers({ DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NO, search: inputValue })
      .then(response => {
        const users = response.data;
        let structuredData = users.map(user => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          };
        });
        callback(structuredData);
      })
      .catch(e => {
        callback([]);
        console.error(e);
      });
  });

  const handleSelectChange = selectedOption => {
    setStore({
      attendanceState: {
        ...attendanceState,
        selectedUserModal: selectedOption,
      },
    });
  };

  const handleDateRange = item => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInModalGlobal: {
          ...attendanceState.dateRangeInModalGlobal,
          dateRange: [item.selection],
        },
      },
    });
  };

  const handleDateRangePicker = () => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInModalGlobal: {
          dateRange: [...attendanceState.dateRangeInModalGlobal.dateRange],
          isOpen: !attendanceState.dateRangeInModalGlobal.isOpen,
        },
      },
    });
  };

  //Open date picker on focus.
  const onFocusDateRangePickerHandler = () => {
    setStore({
      attendanceState: {
        ...attendanceState,
        dateRangeInModalGlobal: {
          dateRange: [...attendanceState.dateRangeInModalGlobal.dateRange],
          isOpen: !attendanceState.dateRangeInModalGlobal.isOpen,
        },
      },
    });
  };

  const onDatePickerClickOutside = () => {
    if (attendanceState.dateRangeInModalGlobal.isOpen) {
      setStore({
        attendanceState: {
          ...attendanceState,
          dateRangeInModalGlobal: {
            dateRange: [...attendanceState.dateRangeInModalGlobal.dateRange],
            isOpen: false,
          },
        },
      });
    }
  };

  const handleCheckInDropdown = (val, type) => {
    setStore({
      attendanceState: {
        ...attendanceState,
        add_modal_timer: {
          checkinTime: {
            ...attendanceState.add_modal_timer.checkinTime,
            [type]: val < 10 ? "0" + val : val.toString(),
          },
          checkoutTime: {
            ...attendanceState.add_modal_timer.checkoutTime,
          },
        },
      },
    });
  };

  const handleCheckOutDropdown = (val, type) => {
    setStore({
      attendanceState: {
        ...attendanceState,
        add_modal_timer: {
          checkoutTime: {
            ...attendanceState.add_modal_timer.checkoutTime,
            [type]: val < 10 ? "0" + val : val.toString(),
          },
          checkinTime: {
            ...attendanceState.add_modal_timer.checkinTime,
          },
        },
      },
    });
  };

  const handleChange = e => {
    setStore({
      attendanceState: {
        ...attendanceState,
        add_modal_notes: e.target.value,
      },
    });
  };

  return (
    <>
      <div className="grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
        <DatePicker
          options={{ type: 0, showRangeBtns: false }}
          _propsState={attendanceState.dateRangeInModalGlobal}
          _propsFunc={{
            handleDateRangePicker,
            handleDateRange,
            onDatePickerClickOutside,
            onFocusDateRangePickerHandler,
          }}
        />
        <div className="flex gap-24 relative">
          <div>
            <label
              htmlFor="employee-check-in"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Check In
            </label>
            <TimeSelect
              buttonClass="" // Customize button styles using Tailwind CSS classes
              dropdownClass="w-40 z-10" // Customize dropdown styles using Tailwind CSS classes
              handleDropdown={handleCheckInDropdown}
              hours={add_modal_timer.checkinTime.hours}
              minutes={add_modal_timer.checkinTime.minutes}
              ampm={add_modal_timer.checkinTime.ampm}
            />
          </div>

          <div>
            <label
              htmlFor="employee-check-out"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Check Out
            </label>
            <TimeSelect
              buttonClass="" // Customize button styles using Tailwind CSS classes
              dropdownClass="w-40" // Customize dropdown styles using Tailwind CSS classes
              handleDropdown={handleCheckOutDropdown}
              hours={add_modal_timer.checkoutTime.hours}
              minutes={add_modal_timer.checkoutTime.minutes}
              ampm={add_modal_timer.checkoutTime.ampm}
            />
          </div>
        </div>

        {isUserSelected && (
          <div>
            <p className="mb-2">Employee Name</p>
            <AsyncSelect
              cacheOptions
              defaultOptions
              isMulti={false}
              value={attendanceState?.selectedUserModal}
              id="customize-balance"
              placeholder="Select Employee"
              loadOptions={options}
              onChange={handleSelectChange}
            />
          </div>
        )}
      </div>

      <div className="mb-[1rem]">
        <label
          htmlFor="leave-reason"
          className="block mt-7 mb-2 text-sm font-medium text-gray-900 dark:text-white">
          Notes
        </label>
        <textarea
          id="leave-reason"
          rows="4"
          name="reason"
          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
          placeholder="Notes"
          onChange={handleChange}
          value={attendanceState.add_modal_notes}></textarea>
      </div>
    </>
  );
});
