import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface maritalStatusState {
  selectedmaritalStatus: string | null; // `string` for maritalStatus values, or `null` if not selected
}

const initialState: maritalStatusState = {
  selectedmaritalStatus: null, // initially no maritalStatus selected
};

const maritalStatusSelectSlice = createSlice({
  name: 'maritalStatus',
  initialState,
  reducers: {
    setSelectedmaritalStatus: (state, action: PayloadAction<string | null>) => {
      state.selectedmaritalStatus = action.payload;
    },
  },
});

export const { setSelectedmaritalStatus } = maritalStatusSelectSlice.actions;

export const selectSelectedmaritalStatus = (state: { maritalStatus: maritalStatusState }) => state.maritalStatus ? state.maritalStatus.selectedmaritalStatus : null; // Safe fallback

export default maritalStatusSelectSlice.reducer;
