export const BloodGroupEnum ={
    NotAvailable: 0,
    OPositive: 1,
    APositive: 2,
    BPositive: 3,
    ABPositive: 4,
    ABNegative: 5,
    ANegative: 6,
    BNegative: 7,
    ONegative: 8
}