import { useState, useEffect } from "react";
import ModalComponent from "../common/Modal/Modal";
import { btnPrimary } from "../../utils/helper";
import { format } from "date-fns";
import {
  getBalanceReports,
  getBalanceTillDate,
  getLeaveBuckets,
  updateLeaveBuckets,
} from "../../services/LeaveService";
import moment from "moment";
import toast from "react-hot-toast";
import { Role } from "../../enum/Role";
import { AddCustomizeBalanceHeading } from "./helper";
import AsyncSelect from "react-select/async";
import { GetUsers } from "../../services/UserService";
import { DEFAULT_PAGE_NO, DEFAULT_PAGE_SIZE } from "../../constants/Constant";
import { debouncedPromiseOptions } from "../../utils/common";
import { MdEditNote } from "react-icons/md";

const AddCustomizeBalance = props => {
  const [users, setUsers] = useState([]);
  // const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [addCustomizeBalance, setAddCustomizeBalance] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const {
    toggleCustomizeBalance,
    setToggleCustomizeBalance,
    selectedUserId,
    setSelectedUserId,
    defaultValue,
    toggleModal,
    setDefaultValue,
    setReFetch,
  } = props;

  const options = debouncedPromiseOptions((inputValue, callback) => {
    GetUsers({ DEFAULT_PAGE_SIZE, DEFAULT_PAGE_NO, search: inputValue })
      .then(response => {
        const users = response.data;
        let structuredData = users.map(user => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          };
        });
        callback(structuredData);
      })
      .catch(e => {
        callback([]);
        console.error(e);
      });
  });

  const resetStates = () => {
    setToggleCustomizeBalance(false);
    setAddCustomizeBalance({});
    setSelectedUserData(null);
    setSelectedUserId(null);
  };

  const getCurrentDate = () => {
    const today = new Date();
    return format(today, "yyyy-MM-dd");
  };

  const fetchLeaveBuckets = () => {
    getBalanceReports(selectedUserId).then(res => {
      const result = res.data.data;
      if (result.length === 0) return;
      setSelectedUserData(result[0]);
    });
  };

  useEffect(() => {
    if (selectedUserId) {
      fetchLeaveBuckets();
    }
  }, [selectedUserId]);

  useEffect(() => {
    const selectedUser = users.find(user => user.id === selectedUserId);
    setSelectedUserData(selectedUser);
  }, [selectedUserId, users, toggleCustomizeBalance]);

  useEffect(() => {
    const isDisabled = selectedUserData?.leaveBuckets?.every(
      bucket => !addCustomizeBalance[bucket.leaveTypeId]?.remainingLeaves
    );
    setIsSubmitDisabled(isDisabled);
  }, [addCustomizeBalance, selectedUserData]);

  const handleUserChange = event => {
    const userId = parseInt(event.target.value);
    setSelectedUserId(userId);
  };

  const onSubmit = async () => {
    let bodyDataArray = [];
    const currentDate = new Date();

    for (let key in addCustomizeBalance) {
      // Skip if remaining leaves are not entered
      if (!addCustomizeBalance[key].remainingLeaves) {
        continue;
      }
      let obj = {};
      obj.leaveTypeId = key;
      obj.newBalance = addCustomizeBalance[key].remainingLeaves;
      obj.reason = addCustomizeBalance[key].reason ?? "";
      obj.date = addCustomizeBalance[key].date
        ? moment(addCustomizeBalance[key].date).format("YYYY-MM-DD")
        : moment(currentDate).format("YYYY-MM-DD");
      bodyDataArray.push(obj);
    }
    await updateLeaveBuckets({
      employeeId: selectedUserId,
      newBalances: bodyDataArray,
    });
    setToggleCustomizeBalance(false);
    toast.success("Update balance successfully");
    setAddCustomizeBalance({});
    setSelectedUserData(null);
    setSelectedUserId(null);
    setReFetch(s => !s);
  };

  const handleFields = (e, bucket) => {
    setAddCustomizeBalance(s => ({
      ...s,
      [bucket.leaveTypeId]: {
        ...s[bucket.leaveTypeId],
        [e.target.name]: e.target.value,
      },
    }));

    if (e.target.name !== "date") return;

    let { employeeId, leaveTypeId } = bucket;
    let date = e.target.value + " " + "00:00:00.000000";
    getBalanceTillDate(employeeId, leaveTypeId, date).then(res => {
      setAddCustomizeBalance(s => {
        return {
          ...s,
          [bucket.leaveTypeId]: {
            ...s[bucket.leaveTypeId],
            data: res.data.data,
          },
        };
      });
    });
  };

  const onChangeDropdownHandler = function (selectedOption) {
    setSelectedUserId(selectedOption.value);
    setDefaultValue(s => ({ ...s, defaultValue: selectedOption }));
  };

  return (
    <div>
      <div>
        <button
          onClick={toggleModal}
          className={`${btnPrimary()} !px-4 !py-2 rounded-md text-white tracking-wide font-semibold flex items-center gap-2`}
          to={""}>
          <MdEditNote />
          Update
        </button>
      </div>

      <ModalComponent
        show={toggleCustomizeBalance}
        size="5xl"
        bodyClassName="gap-4 mt-2"
        heading="Update Leaves"
        showFooter={false}
        onClose={resetStates}>
        <div className="w-[10rem]">
          <AsyncSelect
            cacheOptions
            defaultOptions
            isMulti={false}
            defaultValue={defaultValue}
            key={defaultValue?.label}
            id="customize-balance-modal"
            placeholder="Employees"
            loadOptions={options}
            onChange={onChangeDropdownHandler}
          />
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-4">
          <table className="w-full text-sm text-left rtl:text-right">
            <thead className="text-xs uppercase">
              <tr>
                {AddCustomizeBalanceHeading.map((value, i) => {
                  return (
                    <th key={value} className="px-6 pl-8 py-3">
                      {value}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {selectedUserData?.leaveBuckets?.map((bucket, index) => {
                let availableLeaves = bucket.availableLeaves;
                if (addCustomizeBalance[bucket.leaveTypeId]?.data) {
                  availableLeaves = addCustomizeBalance[bucket.leaveTypeId]?.data.availableLeaves;
                }
                return (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td className="px-6 py-4">{`${bucket.leaveType.name}`}</td>
                    <td className="px-6 py-4">
                      <div>
                        <input
                          type="date"
                          name="date"
                          value={addCustomizeBalance[bucket.leaveTypeId]?.date ?? getCurrentDate()}
                          className="w-36 pl-2 border-b border rounded-lg border-b-gray-400 focus:border-b focus:border-yellow-300 hover:border-yellow-300 focus:ring-0"
                          onChange={e => handleFields(e, bucket)}
                        />
                      </div>
                    </td>
                    <td className="px-16 py-4">{`${availableLeaves}`}</td>
                    <td className="px-6 py-4">
                      <input
                        type="text"
                        name="remainingLeaves"
                        value={addCustomizeBalance[bucket.id]?.remainingLeaves}
                        id="default-search"
                        className="h-2 w-28 block p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-yellow-300 focus:border-yellow-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-300 dark:focus:border-yellow-300"
                        placeholder="New"
                        onChange={e => handleFields(e, bucket)}
                      />
                    </td>

                    <td className="px-6 py-4 text-right">
                      <input
                        type="text"
                        name="reason"
                        value={addCustomizeBalance[bucket.id]?.reason}
                        id={index}
                        className="h-2 w-52 block p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-yellow-300 focus:border-yellow-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-yellow-300 dark:focus:border-yellow-300"
                        placeholder="Manual Correction"
                        onChange={e => handleFields(e, bucket)}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <div className="mt-6 flex justify-end">
          <div>
            <button
              type="button"
              onClick={onSubmit}
              disabled={isSubmitDisabled}
              className={`${
                isSubmitDisabled ? "bg-gray-300 text-gray-600" : `${btnPrimary()} text-white`
              } !px-4 !py-2 rounded-md tracking-wide font-semibold flex items-center gap-2`}>
              {" "}
              Submit
            </button>
          </div>

          <div className=" ml-3">
            <button
              onClick={resetStates}
              type="button"
              className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
              Cancel
            </button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default AddCustomizeBalance;
