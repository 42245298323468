import { Avatar } from "flowbite-react";
import { Tooltip } from "flowbite-react";

import UpdatePassword from "../EmployeeManagement/UpdatePassword";
import ProgressBar from "./../common/Progress Bar/ProgressBar";

import DeleteSvg from "../../media/DeleteSvg";
import { UpdatePasswordSvg } from "../../media/UpdatePasswordSvg";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { toggleUpdatePassword } from "../../store/slices/profileSlice";

const ProfileCard = ({
  handleDeleteUserImage,
  user,
  selectedImage,
  uploadProgress,
  loggedInUser,
  handleImageChange,
}) => {
  //dispatch for toggling update password modal
  const dispatch = useDispatch();
  return (
    <>
      <div className="p-4 mb-4 bg-white rounded-lg shadow sm:p-6 xl:p-8 dark:bg-gray-800">
        <div className="sm:flex xl:block sm:space-x-4 xl:space-x-0">
          <div>
            <h2 className="text-xl text-center font-bold dark:text-white">
              <div className="flex justify-center">
                <label htmlFor="uploadImage">
                  <Avatar
                    alt={user?.firstName + " " + (user?.lastName ?? "")}
                    size="xl"
                    rounded
                    className={`${user?.id === loggedInUser?.id && "cursor-pointer"} pl-5`}
                    img={selectedImage}
                  />
                </label>
                {selectedImage && user?.roleId === loggedInUser?.roleId && (
                  <div className="border rounded-full bg-[#d3d3d3] -left-6 items-center top-4 w-6 h-6 relative">
                    <DeleteSvg
                      dataTooltipId={user?.id}
                      dataTooltipContent="Delete Image"
                      onClick={handleDeleteUserImage}
                      className="w-[10px] m-auto hover:cursor-pointer"
                    />
                    <Tooltip
                      id={user?.id}
                      className="text !text-[11px] text-center font-normal h-10 border !rounded-3xl"
                      cursor="pointer"
                      place="top"
                    />
                  </div>
                )}
              </div>
              <div className="text-center">{`${user?.firstName}  ${user?.lastName ?? ""}`}</div>

              {uploadProgress > 0 && (
                <div className="w-30 mt-3">
                  <ProgressBar
                    labelName="Uploaded:"
                    labelOutside={true}
                    progressValue={uploadProgress}
                  />
                </div>
              )}
            </h2>
            {user?.id === loggedInUser?.id && (
              <input
                id="uploadImage"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={event => handleImageChange(event, user)}
                aria-label="Upload Image"
                hidden={true}
              />
            )}
            <ul className="mt-2 space-y-1">
              {user?.departmentName && (
                <li className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                  <svg
                    className="mr-2 w-4 h-4 text-gray-900 dark:text-white"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                      clipRule="evenodd"></path>
                    <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
                  </svg>

                  {user?.departmentName}
                </li>
              )}
              {user?.designation && (
                <li className="flex items-center text-sm font-normal text-gray-500 dark:text-gray-400">
                  <svg
                    className="w-4 h-4 mr-2 text-gray-900 dark:text-white"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 14l9-5-9-5-9 5 9 5z"></path>
                    <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path>
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path>
                  </svg>

                  {user?.designation}
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="sm:flex xl:block xl:space-y-4">
          <div className="sm:flex-1">
            <address className="text-sm not-italic font-normal text-gray-500 dark:text-gray-400">
              <div className="mt-4">Email</div>
              <p className="text-sm font-medium text-gray-900 dark:text-white">{user?.email}</p>
              <div className="mt-4">Phone</div>
              <div className="mb-2 text-sm font-medium text-gray-900 dark:text-white">
                {user?.phoneNumber}
              </div>
            </address>
            <div className="mt-4">
              <div
                onClick={() => {
                  dispatch(toggleUpdatePassword());
                }}
                className="bg-[white] flex items-center gap-[10px] cursor-pointer w-[max-content]">
                <UpdatePasswordSvg />
                <a className="text-thynkwebPrimary-500">Update Password</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdatePassword />
    </>
  );
};

export default ProfileCard;
