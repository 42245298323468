import { BloodGroupEnum } from "../../../enum/BloodGroupEnum";
import { GenderEnum } from "../../../enum/GenderEnum";
import { MaritalStatusEnum } from "../../../enum/MaritalStatusEnum";

export const genderOptions = (user) => {
  let options=[
      {
        value: GenderEnum.NotAvailable,
        label: "Not Available",
        className: "bg-gray-50 rounded-lg w-[320px]",
        itemClassName: "bg-gray-50 align-center w-[320px]",
        genderId: user.id,
      },

      {
        value: GenderEnum.Male,
        label: "Male",
        className: "bg-gray-50 rounded-lg w-[320px]",
        itemClassName: "bg-gray-50 align-center w-[320px]",
        genderId: user.id,
      },

      {
        value: GenderEnum.Female,
        label: "Female",
        className: "bg-gray-50 rounded-lg w-[320px]",
        itemClassName: "bg-gray-50 w-[320px]",
        genderId: user.id,
      },

      {
        value: GenderEnum.PreferNotToDisclose,
        label: "Prefer Not To Disclose",
        className: "bg-gray-50 rounded-lg w-[320px]",
        itemClassName: "bg-gray-50 w-[320px]",
        genderId: user.id,
      },

      {
        value: GenderEnum.Others,
        label: "Others",
        className: "bg-gray-50 rounded-lg w-[320px]",
        itemClassName: "bg-gray-50 w-[320px]",
        genderId: user.id,
      },
]
return options;
}

export const maritalOptions = (user) => {
  let options=[
      {
        value: MaritalStatusEnum.NotAvailable,
        label: "Not Available",
        className: "bg-gray-50 rounded-lg w-[320px]",
        itemClassName: "bg-gray-50 align-center w-[320px]",
        maritalStatusId: user.id,
      },

      {
        value: MaritalStatusEnum.Married,
        label: "Married",
        className: "bg-gray-50 rounded-lg w-[320px]",
        itemClassName: "bg-gray-50 align-center w-[320px]",
        maritalStatusId: user.id,
      },

      {
        value: MaritalStatusEnum.Unmarried,
        label: "Unmarried",
        className: "bg-gray-50 rounded-lg w-[320px]",
        itemClassName: "bg-gray-50 align-center w-[320px]",
        maritalStatusId: user.id,
      }
]
return options;
}

export const bloodGroupOptions = (user) => {
  let options=[
    {
      value: BloodGroupEnum.NotAvailable,
      label: "Not Available",
      className: "bg-gray-50 rounded-lg w-[320px]",
      itemClassName: "bg-gray-50 align-center w-[320px]",
      bloodGroupId: user.id,
    },

    {
      value: BloodGroupEnum.OPositive,
      label: "OPositive",
      className: "bg-gray-50 rounded-lg w-[320px]",
      itemClassName: "bg-gray-50 align-center w-[320px]",
      bloodGroupId: user.id,
    },

    {
      value: BloodGroupEnum.APositive,
      label: "APositive",
      className: "bg-gray-50 rounded-lg w-[320px]",
      itemClassName: "bg-gray-50 w-[320px]",
      bloodGroupId: user.id,
    },

    {
      value: BloodGroupEnum.BPositive,
      label: "BPositive",
      className: "bg-gray-50 rounded-lg w-[320px]",
      itemClassName: "bg-gray-50 w-[320px]",
      bloodGroupId: user.id,
    },

    {
      value: BloodGroupEnum.ABPositive,
      label: "ABPositive",
      className: "bg-gray-50 rounded-lg w-[320px]",
      itemClassName: "bg-gray-50 w-[320px]",
      bloodGroupId: user.id,
    },

    {
      value: BloodGroupEnum.ABNegative,
      label: "ABNegative",
      className: "bg-gray-50 rounded-lg w-[320px]",
      itemClassName: "bg-gray-50 w-[320px]",
      bloodGroupId: user.id,
    },

    {
      value: BloodGroupEnum.ANegative,
      label: "ANegative",
      className: "bg-gray-50 rounded-lg w-[320px]",
      itemClassName: "bg-gray-50 w-[320px]",
      bloodGroupId: user.id,
    },

    {
      value: BloodGroupEnum.BNegative,
      label: "BNegative",
      className: "bg-gray-50 rounded-lg w-[320px]",
      itemClassName: "bg-gray-50 w-[320px]",
      bloodGroupId: user.id,
    },

    {
      value: BloodGroupEnum.ONegative,
      label: "ONegative",
      className: "bg-gray-50 rounded-lg w-[320px]",
      itemClassName: "bg-gray-50 w-[320px]",
      bloodGroupId: user.id,
    },
]
return options;
}

export const getGender = (user) => {
  let gender = "";
  switch (user?.gender) {
    case 0:
      gender = "Not Available";
      break;
    case 1:
      gender = "Male";
      break;
    case 2:
      gender = "Female";
      break;
    case 3:
      gender = "PreferNotToDisclose";
      break;
    case 4:
      gender = "Others";
      break;
    default:
      gender = "Not Available";  
  }
  return gender;
}

export const getMaritalStatus = (user) => {
  let maritalStatus = "";
  switch (user?.maritalStatus) {
    case 0:
      maritalStatus = "Not Available";
      break;
    case 1:
      maritalStatus = "Married";
      break;
    case 2:
      maritalStatus = "Unmarried";
      break;
    default:
      maritalStatus = "Not Available";  
  }
  return maritalStatus;
}

export const getBloodGroup = (user) => {
  let blood_group = "";
  switch (user?.bloodGroup) {
    case 0:
      blood_group = "Not Available";
      break;
    case 1:
      blood_group = "OPositive";
      break;
    case 2:
      blood_group = "APositive";
      break;
    case 3:
      blood_group = "BPositive";
      break;
    case 4:
      blood_group = "ABPositive";
      break;
    case 5:
      blood_group = "ABNegative";
      break;
    case 6:
      blood_group = "ANegative";
      break;
    case 7:
      blood_group = "BNegative";
      break;
    case 8:
      blood_group = "ONegative";
      break;
    default:
      blood_group = "Not Available";
  }
  return blood_group;
}