import { configureStore } from "@reduxjs/toolkit";
import("react-redux");

//slices
import exceptionLogs from "./slices/exceptionLogsSlice";
import TCSlice from "./slices/TCSlice.js";
import paginationSlice from "./slices/pagination";
import employmentSlice from "./slices/employment";
import profileSlice from "./slices/profileSlice";
import addSkillSlice from "./slices/addSkillSlice.js";
// import payRollSlice from "./slices/payrollSlice.js";
import NotificationSlice from "./slices/NotificationSlice.js";
import sideBarSlice from "./slices/sideBarSlice.js";
import leavesSlice from "./slices/leavesSlice.js";
import logoBrandingSlice from "./slices/logoBrandingSlice.js";
import settingsSlice from "./slices/settingsSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import salarySlice from "./slices/salarySlice";
import statutorySlice from "./slices/statutorySlice";
import onlineStatusReducer from "./slices/onlineStatusSlice";
import salaryStructureSlice from "./slices/salaryStructureSlice";
import userManagementSlice from "./slices/userManagementSlice";
import genderSelectSlice from "./slices/genderSelectSlice";
import maritalStatusSelectSlice from "./slices/maritalStatusSelectSlice";
import bloodGroupSelectSlice from "./slices/bloodGroupSelectSlice";

export const store = configureStore({
  reducer: {
    exceptionLogs,
    TC: TCSlice,
    pagination: paginationSlice,
    employment: employmentSlice,
    profile: profileSlice,
    skills: addSkillSlice,
    // payroll: payRollSlice,
    notification: NotificationSlice,
    sidebar: sideBarSlice,
    leaves: leavesSlice,
    logoBranding: logoBrandingSlice,
    settings: settingsSlice,
    salary: salarySlice,
    salaryStructure: salaryStructureSlice,
    statutory: statutorySlice,
    onlineStatus: onlineStatusReducer,
    userManagement: userManagementSlice,
    genderSelect: genderSelectSlice,
    maritalStatusSelect: maritalStatusSelectSlice,
    bloodGroupSelect: bloodGroupSelectSlice,
  },
});

export type AppStore = ReturnType<typeof store.getState>;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
