import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./LeftMenu.module.css";

const activeLinkStyles = `${styles["tc-left-menu-item-active"]} !mb-0 !px-[.7rem] !py-1.5 !pr-[1.3rem] gap-2`;
const inActiveLinkStyles = `${styles["tc-left-menu-item"]} !mb-0 !px-[.7rem] !py-1.5 !pr-[1.3rem] gap-2`;

const LeftMenu = ({
  children,
  sidebarListItem,
  isObjectContainParent = false,
  onMenuClick,
  activeComponent,
}) => {
  return (
    <div className="bg-gray-50 pt-3 w-[7rem] max-w-[7rem] min-w-[7rem]">
      <ul className="flex flex-col gap-[2px] w-full">
        {!isObjectContainParent &&
          sidebarListItem.map(item => {
            return (
              <li key={item.txt} className="flex flex-col gap-0 mb-5 mt-1">
                <div key={item.txt}>
                  <NavLink
                    to={item.url}
                    className={() =>
                      activeComponent === item.txt ? activeLinkStyles : inActiveLinkStyles
                    }
                    onClick={() => onMenuClick && onMenuClick(item.txt)}>
                    {item.icon}
                    <span className="text-sm text-center">{item.txt}</span>
                  </NavLink>
                </div>
              </li>
            );
          })}

        {isObjectContainParent &&
          sidebarListItem.map(item => {
            return (
              <li key={item.id} className="flex flex-col gap-0 mb-5 mt-1">
                <h5 className="text-[1.3rem] text-thynkwebPrimary-900 font-[600] tracking-wide pl-[.8rem] pb-[.5rem]">
                  {item.parent}
                </h5>
                {item?.children && (
                  <>
                    {item.children.map(child => {
                      return (
                        <div key={child.id}>
                          <NavLink
                            to={child.url}
                            className={() =>
                              activeComponent === child.txt ? activeLinkStyles : inActiveLinkStyles
                            }
                            onClick={() => onMenuClick && onMenuClick(child.txt)} // Only call onMenuClick if it's provided
                          >
                            {child.icon}
                            <span className="text-sm text-center">{child.txt}</span>
                          </NavLink>
                        </div>
                      );
                    })}
                  </>
                )}
              </li>
            );
          })}
        {children}
      </ul>
    </div>
  );
};

export default LeftMenu;
