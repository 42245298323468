import { Tooltip } from "react-tooltip";
import { FaExclamationTriangle, FaExclamationCircle, FaInfoCircle } from "react-icons/fa";
import { ReactNode } from "react";

type IconType = "warning" | "error" | "info";

interface TWTooltipProps {
  text: string;
  children?: ReactNode;
  icon?: IconType;
  className?: string;
  iconClassName?: string;
}

const iconTypes: Record<IconType, React.JSX.Element> = {
  warning: <FaExclamationTriangle color="orange" />,
  error: <FaExclamationCircle color="red" />,
  info: <FaInfoCircle color="gray" />,
}

const TWTooltip: React.FC<TWTooltipProps> = ({ text, children, icon, className = "", iconClassName= "" }) => {
  const tooltipId = `tooltip-${Math.random().toString(36).substr(2, 9)}`;
  
  return (
    <div data-tooltip-html={text} data-tooltip-id={tooltipId} className={`display-inline-block cursor-pointer ${iconClassName}`}>
      {children || iconTypes[icon] || <FaInfoCircle color="gray" />}
      <Tooltip className={`multiline-tooltip ${className}`} id={tooltipId} place="top" />
    </div>
  );
};

export default TWTooltip;
