import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GenderState {
  selectedGender: string | null; // `string` for gender values, or `null` if not selected
}

const initialState: GenderState = {
  selectedGender: null, // initially no gender selected
};

const genderSelectSlice = createSlice({
  name: 'gender',
  initialState,
  reducers: {
    setSelectedGender: (state, action: PayloadAction<string | null>) => {
      state.selectedGender = action.payload;
    },
  },
});

export const { setSelectedGender } = genderSelectSlice.actions;

export const selectSelectedGender = (state: { gender: GenderState }) => state.gender ? state.gender.selectedGender : null; // Safe fallback

export default genderSelectSlice.reducer;
