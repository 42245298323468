import React, { useState, useEffect, useMemo, useCallback } from "react";
import AssignShiftModal from "./ShiftsModal/AssignShiftModal";
import ModalButtons from "../../../common/Modal/ModalButtons";
import AvailableAndAssignShift from "./ShiftsModal/AvailableAndAssignShift";
import { getAssignedShifts } from "../../../../services/AttendanceService";
import { Pagination } from "flowbite-react";
import { LeftArrowSvg } from "../../../../media/LeftArrowSvg";
import { RightArrowSvg } from "../../../../media/RightArrowSvg";
import Calendar2Svg from "../../../../media/Calendar2Svg";

export function EmployeeSchedule() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [zoom, setZoom] = useState(100);
  const [isShiftModalOpen, setShiftModalOpen] = useState(false);
  const [isShiftAssignModalOpen, setShiftAssignModalOpen] = useState(false);
  const [shiftData, setShiftData] = useState({
    employee: "",
    departmentId: null,
    shiftName: "",
    shiftId: null,
    startDate: "",
    endDate: "",
    reason: "",
    shiftStart: "",
    shiftEnd: "",
    totalRecords: "",
    isDefault: false,
  });
  const [employees, setEmployees] = useState([]);
  const [shiftsByDate, setShiftsByDate] = useState({});
  const [shifts, setShifts] = useState([]);

  //Pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size

  // Improved time formatting function
  const formatTime = useCallback(time => {
    if (!time) return "N/A";

    try {
      // Handle backend time format "HH:mm:ss"
      const timeParts = time.split(":");
      if (timeParts.length !== 3) return "N/A";

      const hours = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);

      if (isNaN(hours) || isNaN(minutes)) return "N/A";

      const date = new Date();
      date.setHours(hours, minutes, 0);

      // Format time as 12-hour format
      return date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });
    } catch (error) {
      console.error("Error formatting time:", error);
      return "N/A";
    }
  }, []);

  const processShifts = useCallback(
    (shiftsResponse, employees, weekStart, weekEnd) => {
      const shiftsMap = {};

      // Check if data exists and is an array
      const shiftsData = Array.isArray(shiftsResponse.data) ? shiftsResponse.data : [];

      shiftsData.forEach(employeeShift => {
        const { employeeId, employeeName, departmentId, shifts } = employeeShift;

        // Filter and process unique shifts
        const uniqueShifts = Array.isArray(shifts)
          ? shifts.filter(
              (shift, index, self) =>
                index ===
                self.findIndex(
                  t =>
                    t.shiftId === shift.shiftId &&
                    t.shiftStart === shift.shiftStart &&
                    t.shiftEnd === shift.shiftEnd
                )
            )
          : [];

        uniqueShifts.forEach(shift => {
          // Determine start and end dates
          const shiftStartDate =
            shift.startDate === "0001-01-01T00:00:00" ? weekStart : new Date(shift.startDate);

          const shiftEndDate = shift.endDate ? new Date(shift.endDate) : weekEnd;

          // Ensure dates are within the current week
          const start = new Date(Math.max(weekStart, shiftStartDate));
          const end = new Date(Math.min(weekEnd, shiftEndDate));

          let current = new Date(start);
          while (current <= end) {
            const currentDateKey = current.toLocaleDateString();

            if (!shiftsMap[currentDateKey]) {
              shiftsMap[currentDateKey] = {};
            }

            shiftsMap[currentDateKey][employeeId] = {
              ...shift,
              employeeId,
              employeeName: employeeName || "Unknown Employee",
              departmentId,
              type: shift.shiftName || "Unknown Shift",
              shiftName: shift.shiftName || "N/A",
              shiftStart: formatTime(shift.shiftStart || ""),
              shiftEnd: formatTime(shift.shiftEnd || ""),
              isDefault: shift.isDefault || false,
            };

            current.setDate(current.getDate() + 1);
          }
        });
      });

      return shiftsMap;
    },
    [formatTime]
  );

  // Update fetchData to use the new structure
  const fetchData = async () => {
    try {
      // Calculate date range for fetching shifts
      const weekStart = new Date(currentDate);
      weekStart.setDate(weekStart.getDate() - weekStart.getDay());
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 6);

      // Fetch shifts
      const shiftsResponse = await getAssignedShifts(pageNo, {
        startDate: weekStart.toISOString(),
        endDate: weekEnd.toISOString(),
        pageSize: pageSize,
      });

      // Extract and process employee and shift data from response
      const { data: shiftData } = shiftsResponse;

      // Format employees and shifts for rendering
      const formattedEmployees = shiftData.map(record => ({
        id: record.employeeId,
        name: record.employeeName,
        departmentId: record.departmentId,
        initials: record.employeeName
          ? record.employeeName
              .split(" ")
              .map(name => name[0].toUpperCase())
              .join("")
          : "NA", // Fallback for missing names
        shifts: record.shifts, // Keep shifts for further use
      }));

      // Process shifts
      const processedShifts = processShifts(shiftsResponse, formattedEmployees, weekStart, weekEnd);

      setEmployees(formattedEmployees);
      setShiftsByDate(processedShifts);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Optionally set error state or show user-friendly message
    }
  };

  // Trigger data fetch when current date changes
  useEffect(() => {
    fetchData();
  }, [pageNo, currentDate, formatTime]);

  const openModal = () => setShiftModalOpen(true);
  const closeModal = () => {
    setShiftModalOpen(false);
    setShiftAssignModalOpen(false);
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setShiftData(prev => ({ ...prev, [name]: value }));
  };
  const handleSubmit = async () => {
    await fetchData(); // Ensure data is fetched before closing
    closeModal(); // Close the modal afterward
  };

  const weekStart = useMemo(() => {
    const start = new Date(currentDate);
    start.setDate(start.getDate() - start.getDay());
    return start;
  }, [currentDate]);

  const weekDays = useMemo(
    () =>
      Array.from({ length: 7 }, (_, i) => {
        const day = new Date(weekStart);
        day.setDate(day.getDate() + i);
        return day;
      }),
    [weekStart]
  );

  const isCurrentDay = day => {
    return (
      day.getDate() === currentDate.getDate() &&
      day.getMonth() === currentDate.getMonth() &&
      day.getFullYear() === currentDate.getFullYear()
    );
  };

  const navigateWeek = useCallback(direction => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + (direction === "prev" ? -7 : 7));
      return newDate;
    });
  }, []);

  const formatDate = date =>
    date.toLocaleDateString("en-US", { day: "2-digit", month: "short", year: "numeric" });

  const handleShiftClick = (shift, empId, selectedDate) => {
    setShiftData({
      ...shift,
      departmentId: shift.departmentId,
      employeeId: empId,
      selectedDate,
    });
    setShiftAssignModalOpen(true);
  };

  return (
    <div className="flex h-screen flex-col">
      <header className="flex items-center justify-between border-b py-2">
        <div className="flex items-center gap-72">
          <span className="text-sm font-medium bg-gray-200 text-gray-700 px-2 py-1 rounded">
            {formatDate(weekDays[0])} - {formatDate(weekDays[6])}
          </span>
          <div className="flex items-center">
            <button onClick={() => navigateWeek("prev")} className="p-2">
              <LeftArrowSvg />
            </button>
            <button>
              <Calendar2Svg />
            </button>
            <button onClick={() => navigateWeek("next")} className="p-2">
              <RightArrowSvg />
            </button>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <ModalButtons
            btnCont="row-start-3 row-end-4 col-start-1 col-end-3"
            actionBtnText="Assign Shift"
            onClickAction={openModal}
            actionBtnType="button"
            actionBtnClass="bg-blue-500 text-white px-4 rounded"
            hideActionBtn={false}
            hideCancelBtn={true}
          />
        </div>
        <AssignShiftModal
          isShiftModalOpen={isShiftModalOpen}
          handleCloseModal={closeModal}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          shiftData={shiftData}
        />
      </header>

      <div className="flex flex-1 overflow-hidden">
        {/* Schedule Grid */}
        <div className="flex-1 overflow-auto">
          <div className="grid grid-cols-8 h-full">
            {/* Employees Heading */}
            <div className="sticky top-0 border-b bg-white text-center">
              <div className="text-xs font-bold text-gray-700 p-5">Employees</div>
              <div className="overflow-auto max-h-screen">
                {employees.map(emp => (
                  <div key={emp.id} className="flex items-center p-4 mr-2">
                    <div className="h-6 w-6 rounded-full bg-gray-300 flex items-center justify-center text-xs mr-1">
                      {emp.initials}
                    </div>
                    <span className="text-xs font-medium text-gray-700">{emp.name}</span>
                  </div>
                ))}
              </div>
            </div>
            {weekDays.map((day, dayIndex) => (
              <div
                key={day}
                className={`border relative ${isCurrentDay(day) ? "bg-blue-50" : "bg-white"}`}>
                {/* Day Header */}
                <div className="sticky top-0 border-b bg-white p-2 text-center">
                  <div className="text-xs text-gray-500">
                    {day.toLocaleDateString("en-US", { weekday: "short" })}
                  </div>
                  <div className="text-sm font-semibold">{day.getDate()}</div>
                </div>

                {/* Shifts Grid */}
                <div className="grid grid-rows-[repeat(auto-fill,minmax(40px,1fr))]">
                  {employees.map(emp => {
                    const shift = shiftsByDate[day.toLocaleDateString()]?.[emp.id];
                    const isDefault = shift?.isDefault || false; // Safely access shift.isDefault
                    const bgColor = isDefault ? "bg-gray-100" : "bg-green-100";

                    return (
                      <div
                        key={emp.id}
                        className={`py-4 px-1 border-b flex items-center justify-between ${bgColor}`}
                        onClick={() => handleShiftClick(shift, emp.id, day)}
                        role="button"
                        tabIndex={0}
                        aria-label={`Shift details for ${emp.name}`}
                        onKeyDown={e => e.key === "Enter" && handleShiftClick(shift, emp.id, day)}>
                        <div className="flex items-center space-x-4">
                          <div className="text-xs">
                            {shift ? (
                              <>
                                <div className="font-semibold text-gray-700">
                                  {shift.shiftName || "Unnamed Shift"}
                                </div>
                                <div className="text-[.7rem] text-gray-500">
                                  {shift.shiftStart || "Start time"} -{" "}
                                  {shift.shiftEnd || "End time"}
                                </div>
                              </>
                            ) : (
                              <div className="text-[.8rem] text-gray-400 italic">
                                No shift assigned
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Shift Assignment Modal (assuming you have this component) */}
        <AvailableAndAssignShift
          isShiftModalOpen={isShiftAssignModalOpen}
          handleCloseModal={closeModal}
          handleSubmit={handleSubmit}
          handleInputChange={handleInputChange}
          shiftData={shiftData}
        />
      </div>
      {/* Flowbite Pagination */}
      <div className="flex justify-end my-4">
        <Pagination
          currentPage={pageNo}
          onPageChange={page => setPageNo(page)}
          showIcons={true}
          totalPages={
            shiftData?.totalRecords === undefined
              ? 1
              : Math.ceil(shiftData?.totalRecords / pageSize)
          }
        />
      </div>
    </div>
  );
}
