import { useEffect, useRef, useState } from "react";
import CardComponent from "../common/Card/Card";
import { cardComponents } from "./PayrollCommon";
import { Button, Dropdown } from "flowbite-react";
import styles from "./Payroll.module.css";
import { useStore } from "../../context/createFastContext";
import Table from "../common/Table/Table";
import {
  addSalaryReleaseEarningToPayslip,
  approvePayroll,
  deletePayroll,
  getDeductions,
  getEarnings,
  getPayrollById,
  getWithheldPayslips,
  recordPayments,
  rejectPayrollApprovalById,
  resetPayslipById,
  revertWithholdPayslip,
  sendPayslip,
  updatePayslipComponents,
  withHoldPayslip,
} from "../../services/PayrollService";
import { useParams } from "react-router";
import ModalComponent from "../common/Modal/Modal";
import PreviewModalComponent from "./PreviewModalComponent";
import ApproveModalComponent from "./ApproveModalComponent";
import { LoadingSvg } from "../../media/LoadingSvg";
import { showToast } from "../common/Toast";
import RecordpaymentModal from "./RecordpaymentModal";
import { DeleteEntity } from "../common/DeleteEntity";
import { formattedAmount, formattedDate } from "../../utils/common";
import { btnPrimary, convert_Date } from "../../utils/helper";
import { Checkbox, useCheckbox } from "../../hooks/useCheckbox";
import { PayrollStatus } from "../../enum/PayrollStatus";
import { useNavigate } from "react-router-dom";
import { STATUS_CODES } from "../../constants/Constant";
import MultiSelect from "../common/MultiSelect/MultiSelect";
import { format } from "date-fns";
import TWTooltip from "../common/TWTooltip";

const ViewDetails = () => {
  const [payrollState, setStore] = useStore(s => s.payrollState);
  const [isChange, setIsChange] = useState(false);
  const allRecordsSelected = JSON.parse(localStorage.getItem("allRecordsSelected")) || true;
  const selectedRecords = JSON.parse(localStorage.getItem("selectedRecords"));

  const selectAllRef = useRef();
  const navigate = useNavigate();

  const initialState = {
    allChecked: false,
    checkedItems: [],
  };
  const [employeesList, setEmployeesList] = useState([]);
  const [selectedCheckedbox, setSelectedCheckedbox] = useState(initialState);
  const { isSelectedHandler } = useCheckbox(
    setSelectedCheckedbox,
    selectedCheckedbox,
    employeesList,
    selectAllRef
  );

  const { id } = useParams();

  // Fetch payroll by Id
  const getPayroll_ById = async () => {
    const responseEarnings = await getEarnings(true).then(res => {
      return res?.data;
    });

    const responseDeductions = await getDeductions(true).then(res => {
      return res?.data;
    });

    const res = await getPayrollById(id);
    const data = await res.data;

    if (res?.message === "Success!") {
      if (data?.payrollDetails?.status === PayrollStatus.Draft) {
        setStore({
          payrollState: {
            ...payrollState,
            res: data,
            paydate: formattedDate(data?.payrollDetails?.payDate),
            status: data?.payrollDetails?.status,
            responseEarnings: responseEarnings,
            responseDeductions: responseDeductions,
          },
        });
        setEmployeesList(
          data.payslips?.map(payslip => payslip.status !== PayrollStatus.PaymentCompleted && { id: payslip?.id })
        );
      } 
      else if 
      (
        data?.payrollDetails?.status === PayrollStatus.Approved ||
        data?.payrollDetails?.status === PayrollStatus.PaymentCompleted
      ) {
        const paydate = data?.payrollDetails?.payDate;
        const _paydate = convert_Date(
          true,
          false,
          paydate,
          "en-US",
          "2-digit",
          "2-digit",
          "numeric",
          "/"
        );

        const payDate = `${_paydate[2]}-${_paydate[0]}-${_paydate[1]}`;

        const updatedEmployeeList = data.payslips?.filter(
          payslip => payslip.status !== PayrollStatus.PaymentCompleted && { id: payslip?.id }
        );

        setEmployeesList(updatedEmployeeList);
        setStore({
          payrollState: {
            ...payrollState,
            loading: false,
            res: data,
            recordPaymentBody: {
              ...payrollState.recordPaymentBody,
              paymentDate: payDate,
            },
            status: data.payrollDetails?.status,
          },
        });
      }
    } else if (res?.message !== "Success!") navigate("/404-Page-Not-Found");
  };

  useEffect(() => {
    getPayroll_ById();
  }, [
    id,
    payrollState?.status,
    payrollState?.approvedPayrollState,
    payrollState?.recordPayrollState,
    payrollState.renderPreviewModal,
    payrollState.refetchPayroll
  ]);

  // Close preview modal
  const handlePreviewModalClose = () => {
    setStore({
      payrollState: {
        ...payrollState,
        selectedEarningOptions: [],
        selectedDeductionOptions: [],
        lopDays: payrollState.previewRowObj.lopDays,
        renderPreviewModal: !payrollState.renderPreviewModal,
        previewModal: !payrollState.previewModal,
      },
    });
  };

  // Close approve modal
  const handleApproveModalClose = () => {
    setStore({
      payrollState: {
        ...payrollState,
        approveModal: !payrollState.approveModal,
      },
    });
  };

  // To open approve modal
  const openApproveModal = () => {
    setStore({
      payrollState: {
        ...payrollState,
        approveModal: !payrollState.approveModal,
      },
    });
  };

  // Close record modal
  const handleRecordModalClose = () => {
    setStore({
      payrollState: {
        ...payrollState,
        recordModal: !payrollState.recordModal,
      },
    });
  };

  // To open record modal
  const openRecordModal = () => {
    setStore({
      payrollState: {
        ...payrollState,
        recordModal: !payrollState.recordModal,
      },
    });
  };

  // Function to approve payroll
  const handleApprove = async () => {
    const payrollDetails = payrollState?.res?.payrollDetails;
    const payrollId = payrollDetails?.id;

    const approvePay = await approvePayroll(payrollId || id).then(res => {
      return res;
    });

    setStore({
      payrollState: {
        ...payrollState,
        loading: true,
      },
    });

    if (approvePay.message === "Success!" && approvePay.statusCode === 200) {
      const updatedPayrollState = {
        loading: false,
        res: approvePay.data,
        status: approvePay.data?.payrollDetails?.status,
      };

      setStore({
        payrollState: {
          ...payrollState,
          approveModal: !payrollState.approveModal,
          approvedPayrollState: updatedPayrollState,
        },
      });
      showToast("success", "Payrun has been Approved");
    }
  };

  // On clicking any of the users in the payroll, set previewRowObj object.
  const tableClick = e => {
    const elem = e.target.closest("[data-id]");

    if (!elem) return;
    const checkbox = elem.querySelector('input[type="checkbox"]');

    if (checkbox && e.target === checkbox) return;
    const rowData = JSON.parse(elem.dataset.id);

    setStore({
      payrollState: {
        ...payrollState,
        previewModal: !payrollState.previewModal,
        previewRowId: rowData.id,
        previewRowObj: {
          ...payrollState.previewRowObj,
          ...rowData,
        },
      },
    });
  };

  // Function to record payment
  const handleRecordPayment = async () => {
    setStore({
      payrollState: {
        ...payrollState,
        loading: true,
      },
    });

    const paydate = payrollState?.paydate;
    const _paydate = convert_Date(
      true,
      false,
      paydate,
      "en-US",
      "2-digit",
      "2-digit",
      "numeric",
      "/"
    );

    const payDate = `${_paydate[2]}-${_paydate[0]}-${_paydate[1]}`;
    const selectedIds = selectedCheckedbox.checkedItems.filter((item, i) => item !== undefined);
    const bodyData = {
      paymentDate: payrollState.recordPaymentBody.paymentDate || payDate,
      payrollRunId: payrollState.res.payrollDetails.id,
      payslipIds: selectedIds,
    };

    const record_payments = await recordPayments(bodyData).then(response => {
      return response;
    });

    if (record_payments?.message === "Success!" && record_payments?.statusCode === 200) {
      const updatedPayrollState = {
        loading: false,
        res: record_payments.data,
        status: record_payments.data?.payrollDetails?.status,
      };

      const updatedEmployeeList = record_payments?.data.payslips?.filter(
        payslip => payslip.status !== PayrollStatus.PaymentCompleted && { id: payslip?.id }
      );

      setEmployeesList(updatedEmployeeList);

      setStore({
        payrollState: {
          ...payrollState,
          recordModal: !payrollState.recordModal,
          recordPayrollState: updatedPayrollState,
        },
      });

      if (selectedIds.length === 0 || selectedIds.length === employeesList.length) {
        showToast("success", "Payments have been marked as paid.");
        setSelectedCheckedbox({
          allChecked: false,
          checkedItems: [],
        });
        localStorage.removeItem("selectedRecords");
        localStorage.setItem("allRecordsSelected", JSON.stringify(true));
      } else {
        setSelectedCheckedbox({
          ...selectedCheckedbox,
          checkedItems: [],
        });
        localStorage.removeItem("allRecordsSelected");
        localStorage.setItem("selectedRecords", JSON.stringify(true));
        setStore({
          payrollState: {
            ...payrollState,
            recordModal: !payrollState.recordModal,
            approvedPayrollState: updatedPayrollState,
          },
        });
        showToast("success", "Payment has been recorded.");
      }
    }
  };

  const resetPayslip = async () => {
    const payslipId = payrollState.previewRowObj.id;
    await resetPayslipById(payslipId)
      .then(response => {
        if (response?.message === "Success!" && response?.statusCode === 200) {
          setStore({
            payrollState: {
              ...payrollState,
              loading: false,
              renderPreviewModal: !payrollState.renderPreviewModal,
              previewModal: !payrollState.previewModal,
            },
          });
          showToast("success", "Payslip reset to current salary structure");
        }
      })
      .catch(err =>
        setStore({
          payrollState: {
            ...payrollState,
            loading: false,
          },
        })
      );
  }

  // Function to save payment summary at draft level
  const handleSave_PaymentSummary = async () => {
    const initialPayslipEarnings = payrollState.previewRowObj.payslipComponents;
    const updatedPayslipEarnings = payrollState.selectedEarningOptions.map(selectedEarning => {
      const matchingEarning = initialPayslipEarnings.find(psEarning => psEarning.salaryComponentId === selectedEarning.salaryComponentId);
      const id = matchingEarning ? matchingEarning.id : 0;

      return {
        id: id,
        salaryComponentId: selectedEarning.salaryComponentId,
        amount: selectedEarning.amount
      }
    })
    const initialPayslipDeductions = payrollState.previewRowObj.payslipDeductions;
    const updatedPayslipDeductions = payrollState.selectedDeductionOptions.map(selectedDeduction => {
      const matchingDeduction = initialPayslipDeductions.find(psDeduction => psDeduction.tenantDeductionId === selectedDeduction.tenantDeductionId);
      const id = matchingDeduction ? matchingDeduction.id : 0;
      return {
        id: id,
        tenantDeductionId: selectedDeduction.tenantDeductionId,
        amount: selectedDeduction.amount
      }
    })

    const bodyData = {
      payslipComponents: updatedPayslipEarnings,
      payslipDeductions: updatedPayslipDeductions,
      PayslipStatutoryComponents: payrollState.payslipStatutoryComponents,
      payrollRunId: payrollState?.previewRowObj.payrollRunId,
      payslipId: payrollState?.previewRowObj.id,
      lopDays: payrollState.previewRowObj.lopDays,
    };

    // Update payslip of the user.
    await updatePayslipComponents(bodyData)
      .then(response => {
        if (response?.message === "Success!" && response?.statusCode === 200) {
          setStore({
            payrollState: {
              ...payrollState,
              loading: false,
              renderPreviewModal: !payrollState.renderPreviewModal,
              previewModal: !payrollState.previewModal,
            },
          });
          showToast("success", "Employee PayrollRun details updated");
        }
      })
      .catch(err =>
        setStore({
          payrollState: {
            ...payrollState,
            loading: false,
          },
        })
      );
  };

  const onClickSendPayslipHandler = async () => {
    const clientURI = location.origin;
    const payrollId = id;
    if (selectedCheckedbox.checkedItems.length === 0) {
      const { toastId, toast } = showToast("loading", "Sending payslips...");
      const res = await sendPayslip(0, payrollId, clientURI);

      if (res.statusCode === STATUS_CODES.SUCCESS) {
        toastId && toast.dismiss(toastId);
        showToast("success", "Payslips sent successfully");
        return;
      }
    } else
      selectedCheckedbox.checkedItems.forEach(
        async item => await sendPayslip(+item, payrollId, clientURI)
      );
  };

  const rejectPayrollApproval = async () => {
    const res = await rejectPayrollApprovalById(id);
    if (res.statusCode === STATUS_CODES.SUCCESS) {
      showToast("success", "Approval reverted");
    }
    refetch();
  }

  const refetch = () => {
    setStore({
      payrollState: {
        ...payrollState,
        refetchPayroll: !payrollState.refetchPayroll
      },
    });
  }

  const withHoldPayslipById = async (payslipId) => {

    const res = await withHoldPayslip(payslipId)
    if(res) {
      showToast("success", "Payslip WithHeld");
    }
    refetch();
  }

  const revertWithholdPayslipById = async (payslipId) => {
    const res = await revertWithholdPayslip(payslipId);
    if(res) {
      showToast("success", "Payslip withhold reverted");
    }
    refetch();
  }

  // const skipPayslipById = (payslipId) => {
  //   console.log(payslipId);
  //   refetch();
  // }

  const getPayslipTableColumns = () => {
    const checkBoxWithEmployeeNameHeader = (
      <td className="flex gap-2">
        <Checkbox
          id="select-all"
          data="select-all"
          ref={selectAllRef}
          checked={selectedCheckedbox.allChecked}
          disabled={false}
          indeterminate={true}
          onChange={e => isSelectedHandler(e)}
        />
        <>Employee Name</>
      </td>
    );

    switch(payrollState.status)
    {
      case PayrollStatus.Draft:
        return ["Employee Name", "Paid Days", "Gross Pay", "Deductions", "Taxes", "Net Pay", "Actions"];
      case PayrollStatus.Approved:
      case PayrollStatus.PaymentCompleted:
        return [checkBoxWithEmployeeNameHeader, "Paid Days", "Net Pay", "Payslip", "Payment Status"];
      default:
        return null;
    }
  }

  const onReleaseWithheldSalary = async (payslip) => {
    console.log("On opening withheld modal for this payslip", payslip)
    const res = await getWithheldPayslips(payslip.employeeId);
    setStore({
      payrollState: {
        ...payrollState,
        showReleasePayslipModal: !payrollState.showReleasePayslipModal,
        withHeldPayslips: res?.data,
        currentPayslipId: payslip.id
      },
    });
  }

  const getReleasePayslipModalOptions = () => { 
      console.log(payrollState);
      return payrollState.withHeldPayslips.map(item => ({
      value: item.payslipId,
      label: `${format(new Date(item.withHeldPayslipDate), 'MMM yyyy')} - ₹${Math.round(item.amount)}`
    }));
  }

  const onChangeReleasePayslipDate = (payslips) => {
    console.log("release payslip date change", payslips);
    const selectedPayslipIds = payslips.map(ps => {
      console.log(ps);
      return ps.value;
    });

    setStore({
      payrollState: {
        ...payrollState,
        selectedWithHeldPayslipIds: selectedPayslipIds
      },
    })
  }

  const onSaveReleasePayslipDetails = async () => {
    const data = {
      payslipIdsToRelease: payrollState.selectedWithHeldPayslipIds,
      currentPayslipId: payrollState.currentPayslipId
    }
    const res = await addSalaryReleaseEarningToPayslip(data);
    if(res) {
      showToast("success", "Succesfully added salary release earning");
    }
    setStore({
      payrollState: {
        ...payrollState,
        showReleasePayslipModal: false,
        selectedWithHeldPayslipIds: [],
        currentPayslipId: 0,
        refetchPayroll: !payrollState.refetchPayroll
      },
    });
  }
  
  const getPayslipsDraftStatusRows = () => {
    return payrollState?.res.payslips.map(ps => {
      const actionDropdown = ps.status === PayrollStatus.SalaryWithheld ? (
        <>
          <div className="flex gap-4">
            <Dropdown className="z-[1] w-[max-content]" label={"•••"} inline={true} arrowIcon={false}>
              <Dropdown.Item
                onClick={async () => {
                  await revertWithholdPayslipById(ps.id);
                }}
              >
                Revert Salary Withhold
              </Dropdown.Item>
              {/* <Dropdown.Item
              onClick={async () => {
                skipPayslipById(ps.id);
              }}
              >
                Skip Payslip
            </Dropdown.Item> */}
            </Dropdown>
            <TWTooltip text="Salary on hold for this month." icon={"info"}></TWTooltip>
          </div>
        </>
      ) : (
        <>
          <div className="flex gap-4">
            <Dropdown className="z-[1] w-[max-content] bg-white" label={"•••"} inline={true} arrowIcon={false}>
              <Dropdown.Item
                onClick={async () => {
                  await withHoldPayslipById(ps.id);
                }}
              >
                Withhold Salary
              </Dropdown.Item>
              {/* <Dropdown.Item
              onClick={async () => {
                skipPayslipById(ps.id);
              }}
              >
                Skip Payslip
            </Dropdown.Item> */}
              {ps.isSalaryWithheldForPreviousMonths && (
                <Dropdown.Item
                  onClick={async () => {
                    onReleaseWithheldSalary(ps);
                  }}
                >
                  Release Withheld Salary
                </Dropdown.Item>
              )}
            </Dropdown>
            {ps.isSalaryWithheldForPreviousMonths && <TWTooltip text="Salary on hold for previous month(s)." icon={"warning"}></TWTooltip>}
          </div>
        </>
      );
      const payslip = [
        ps,
        [
          <div onClick={tableClick} className="font-medium text-gray-900 whitespace-nowrap">
            {ps.firstName + " " + ps.lastName}
          </div>,
          <div onClick={tableClick}>{ps.paidDays}</div>,
          <div onClick={tableClick}>{formattedAmount(ps.grossEarnings, "en-IN", "INR", 2, 2)}</div>,
          <div onClick={tableClick}>{formattedAmount(ps.deductions, "en-IN", "INR", 2, 2)}</div>,
          <div onClick={tableClick}>{formattedAmount(ps.taxes, "en-IN", "INR", 2, 2)}</div>,
          <div onClick={tableClick}>{formattedAmount(ps.totalNetPayable, "en-IN", "INR", 2, 2)}</div>,
          actionDropdown,
        ]
      ];
      return payslip;
    })
  }

  const getCheckboxWithName = (payslip) => {
    const isCheckboxChecked = selectedCheckedbox.checkedItems.includes(payslip.id.toString());
    switch(payslip.status)
    {
      case PayrollStatus.Approved:
        return (
        <td onClick={tableClick}
          className={`flex gap-2 items-center font-medium text-gray-900 whitespace-nowrap`}>
          <Checkbox
            id={payslip?.id}
            data={payslip?.id}
            checked={isCheckboxChecked}
            disabled={payslip?.status !== PayrollStatus.Approved}
            inputClass={
              payslip?.status === PayrollStatus.PaymentCompleted
                ? `hover:cursor-no-drop`
                : ""
            }
            onChange={e => isSelectedHandler(e)}
          />
          <div className="font-medium text-gray-900 whitespace-nowrap">
            {payslip.firstName + " " + payslip.lastName}
          </div>
        </td>
        );

      case PayrollStatus.PaymentCompleted:
        return (
          <td onClick={tableClick}
          className={`flex gap-2 items-center font-medium text-gray-900 whitespace-nowrap`}>
          <Checkbox
            id={payslip?.id}
            data={payslip?.id}
            checked={isCheckboxChecked}
            disabled={payslip.payslipSent}
            inputClass={
              !payslip.payslipSent
                ? `hover:cursor-no-drop`
                : ""
            }
            onChange={e => isSelectedHandler(e)}
          />
          <div className="font-medium text-gray-900 whitespace-nowrap">
            {payslip.firstName + " " + payslip.lastName}
          </div>
        </td>
        );
        
      case PayrollStatus.PaymentSkipped:
      case PayrollStatus.SalaryOnWithholdReleased:
      case PayrollStatus.SalaryWithheld:
        return (
          <td onClick={tableClick}
          className={`flex gap-2 items-center font-medium text-gray-900 whitespace-nowrap`}>
          <Checkbox
            id={payslip?.id}
            data={payslip?.id}
            checked={false}
            disabled={true}
            onChange={e => isSelectedHandler(e)}
          />
          <div className="font-medium text-gray-900 whitespace-nowrap">
            {payslip.firstName + " " + payslip.lastName}
          </div>
        </td>
        );
    }
  }

  const getPayslipPaymentStatus = (payslip) => {
    switch(payslip.status)
    {
      case PayrollStatus.Draft:
      case PayrollStatus.Approved:
        return (<div onClick={tableClick}>Yet to Pay</div>);
      case PayrollStatus.PaymentCompleted:
        return (<div className="text-green-500 font-semibold" onClick={tableClick}>Paid on {convert_Date(
                              false,
                              false,
                              payslip.paymentDate,
                              "en-GB",
                              "2-digit",
                              "2-digit",
                              "numeric"
                            )} </div>);
      case PayrollStatus.PaymentSkipped:
        return (<div onClick={tableClick}>Payment skipped</div>);
      case PayrollStatus.SalaryWithheld:
        return (<div className="text-red-500 font-semibold" onClick={tableClick}>Salary withhold</div>);
      case PayrollStatus.SalaryOnWithholdReleased:
        return (<div className="text-green-500 font-semibold" onClick={tableClick}>Withheld salary released</div>);
    }
  }

  const getPayslipOtherStatusRows = () => {
    return payrollState?.res.payslips.map(ps => {
      const payslip = [
        ps,
        [
          getCheckboxWithName(ps),
          <div onClick={tableClick}>{ps.paidDays}</div>,
          <div onClick={tableClick}>{formattedAmount(ps.totalNetPayable, "en-IN", "INR", 2, 2)}</div>,
          <div onClick={tableClick} className="text-blue-500 font-semibold">View</div>,
          getPayslipPaymentStatus(ps)
        ]
      ];
      return payslip;
    })
  }
  
  const getPayslipTableRows = () => {
    switch(payrollState.status)
    {
      case PayrollStatus.Draft:
        return getPayslipsDraftStatusRows();
      case PayrollStatus.Approved:
      case PayrollStatus.PaymentCompleted:
        return getPayslipOtherStatusRows();
      default:
        return null
    }
  }

  return (
    <>
      {payrollState?.res?.payrollDetails && (
        <section
          className={`${styles.gradientBg} ${styles.darkGradientBg} bg-gray-100 dark:bg-gray-900 py-3 sm:py-5`}>
          <div className="px-4 mx-auto lg:px-12">
            <div className="flex w-[100%] justify-between">
              <div className="flex">
                <p
                  className={`${styles.textGradientAnimation} text-4xl text-gray-900 dark:text-white py-4 cursor-pointer`}>
                  Regular Payroll
                </p>
                <span
                  className={`w-18 text-center ml-2 mt-7 h-6 ${
                    payrollState?.status === PayrollStatus.Approved
                      ? "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300"
                      : payrollState?.status === PayrollStatus.Draft
                        ? "bg-indigo-100 text-indigo-800 dark:bg-indigo-900 dark:text-indigo-300"
                        : "bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-300"
                  } text-md font-medium mr-2 px-2.5 rounded`}>
                  {payrollState?.status === PayrollStatus.Draft
                    ? "DRAFT"
                    : payrollState?.status === PayrollStatus.Approved &&
                        (allRecordsSelected || selectedRecords)
                      ? "PAYMENT DUE"
                      : "PAID"}
                </span>
              </div>

              <div className="py-4 flex gap-2">
                {payrollState?.status === PayrollStatus.Approved && (
                  <Button
                    onClick={rejectPayrollApproval}
                    className={`cursor-pointer bg-thynkwebPrimary-800 hover:bg-thynkwebPrimary-900 focus:ring-4 focus:ring-thynkwebPrimary-300 dark:focus:ring-thynkwebPrimary-900`}>
                    Revert Approval
                  </Button>
                )}

                <Button
                  onClick={
                    payrollState?.status === PayrollStatus.Draft
                      ? openApproveModal
                      : payrollState?.status === PayrollStatus.Approved &&
                          (allRecordsSelected || selectedRecords)
                        ? openRecordModal
                        : onClickSendPayslipHandler
                  }
                  disabled={payrollState?.res?.payrollDetails?.payslipSent}
                  className={`${
                    selectedCheckedbox.checkedItems.length > 0 && "hidden"
                  } cursor-pointer bg-thynkwebPrimary-800 hover:bg-thynkwebPrimary-900 focus:ring-4 focus:ring-thynkwebPrimary-300 dark:focus:ring-thynkwebPrimary-900`}>
                  {payrollState?.status === PayrollStatus.Draft
                    ? "Submit and Approve"
                    : payrollState?.status === PayrollStatus.Approved &&
                        (allRecordsSelected || selectedRecords)
                      ? "Record Payment"
                      : "Send Payslip"}
                </Button>

                <DeleteEntity
                  id={payrollState.currentPayrollId || id}
                  setIsChange={setIsChange}
                  isChange={isChange}
                  onchange={deletePayroll}
                  _navigate={true}
                  urlToNavigate={"/payroll/payroll-run"}
                  buttonName="Delete Pay Run"
                  heading="Delete Payroll"
                  deleteMessage="You are about to delete a payroll. Are you sure you want to proceed?"
                  className="px-5 py-2.5 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800"
                  successToastMessage={`Payroll successfully deleted`}
                  infoToastMessage={`Payroll Not Deletable`}
                />
              </div>
            </div>

            <div className="overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg px-4">
              <div className="px-4 divide-y dark:divide-gray-700 flex flex-col py-3 space-y-3 md:flex-row md:items-center md:justify-between md:space-y-0 md:space-x-4"></div>
              <div className="w-full fluid grid gap-4 mb-4 md:gap-2 md:grid-cols-4 sm:mb-8 ">
                {cardComponents(payrollState).map((cardComponent, i) => {
                  return (
                    <CardComponent
                      key={i}
                      heading={cardComponent.heading}
                      title={cardComponent.title}
                      titleClassName={cardComponent.titleClassName}
                      cardClassName={cardComponent.cardClassName}
                    />
                  );
                })}
              </div>

              {/* Employee Summary */}
              <div className="overflow-x-auto">
                <div className="mb-2 text-lg flex justify-between font-bold text-gray-500 dark:text-gray-800">
                  <div className="pt-3">Employee Summary</div>
                  <div>
                    {payrollState?.status === PayrollStatus.Approved && (allRecordsSelected || selectedRecords) && (
                      <Button
                        onClick={openRecordModal}
                        className={`${
                          selectedCheckedbox.checkedItems.length === 0 && "hidden"
                        } cursor-pointer bg-thynkwebPrimary-800 hover:bg-thynkwebPrimary-900 focus:ring-4 focus:ring-thynkwebPrimary-300 dark:focus:ring-thynkwebPrimary-900 disabled:`}>
                        Record Payment
                      </Button>
                    )}
                    {payrollState?.status === PayrollStatus.PaymentCompleted && (
                      <Button
                        onClick={onClickSendPayslipHandler.bind(null, 0)}
                        disabled={payrollState?.res?.payslips?.map(
                          payslip =>
                            selectedCheckedbox.checkedItems.includes(payslip.id) &&
                            payslip.payslipSent
                        )}
                        className={`${
                          selectedCheckedbox.checkedItems.length === 0 && "hidden"
                        } cursor-pointer bg-thynkwebPrimary-800 hover:bg-thynkwebPrimary-900 focus:ring-4 focus:ring-thynkwebPrimary-300 dark:focus:ring-thynkwebPrimary-900 disabled:`}>
                        Send Payslip
                      </Button>
                    )}
                  </div>
                </div>
                
                {/* Payslips table */}
                <div className="mx-auto">
                  <div className="overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
                    <Table
                      col={getPayslipTableColumns()}
                      rows={getPayslipTableRows()}
                      >
                    </Table>
                  </div>
                </div>
              </div>

              {/* Payment Summary Modal */}
              {payrollState.previewModal && (
                <ModalComponent
                  heading="Payment Summary"
                  show={payrollState.previewModal}
                  onClose={handlePreviewModalClose}
                  bodyClassName="!px-0"
                  showFooter={false}>
                  <PreviewModalComponent
                    modalClose={handlePreviewModalClose}
                    renderPreviewModal={payrollState.renderPreviewModal}
                  />

                  <div className="flex gap-[1rem] w-[100%] justify-end px-6 mt-4">
                    {payrollState?.status === PayrollStatus.Draft ? (
                      <>
                        <Button
                          onClick={handleSave_PaymentSummary}
                          className={`${btnPrimary()} !px-3`}>
                          {payrollState.loading && <LoadingSvg />}
                          Save
                        </Button>
                        <Button
                          onClick={resetPayslip}
                          className={`${btnPrimary()} !px-3`}>
                          {payrollState.loading && <LoadingSvg />}
                          Reset
                        </Button>
                        <Button onClick={handlePreviewModalClose} color="gray">
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button onClick={handlePreviewModalClose} color="gray">
                        Close
                      </Button>
                    )}
                  </div>
                </ModalComponent>
              )}
              {/* Approve Payroll Modal */}
              <ModalComponent
                heading="Approve Payroll"
                show={payrollState.approveModal}
                onClose={handleApproveModalClose}
                footer={
                  <div className="flex gap-[1rem] w-[100%] justify-end">
                    <Button onClick={handleApprove} className={`${btnPrimary()} !px-3`}>
                      {payrollState.loading && <LoadingSvg />}
                      Submit and Approve
                    </Button>
                    <Button color="gray" onClick={handleApproveModalClose}>
                      Cancel
                    </Button>
                  </div>
                }>
                <ApproveModalComponent />
              </ModalComponent>

              {/* Record Payment Modal */}
              <ModalComponent
                heading="Record Payment"
                show={payrollState.recordModal}
                onClose={handleRecordModalClose}
                footer={
                  <div className="flex gap-[1rem] w-[100%] justify-end">
                    <Button onClick={handleRecordPayment} className={`${btnPrimary()} !px-3`}>
                      {payrollState.loading && <LoadingSvg />}
                      Confirm
                    </Button>
                    <Button color="gray" onClick={handleRecordModalClose}>
                      Cancel
                    </Button>
                  </div>
                }>
                <RecordpaymentModal />
              </ModalComponent>

              {/* Pagination To be thought later as to be added or not in employee summary */}
            </div>
          </div>

          {/* Release Payslip Modal */}
          <ModalComponent
            show={payrollState.showReleasePayslipModal}
            bodyClassName="gap-4 mt-2"
            heading="Release Withheld Payslips"
            footer={<Button className={btnPrimary()} onClick={async () => await onSaveReleasePayslipDetails()}>Save</Button>}
            onClose={() => {
              setStore({
                payrollState: {
                  ...payrollState,
                  showReleasePayslipModal: false,
                  selectedWithHeldPayslipIds: [],
                  currentPayslipId: 0
                },
              })
            }}
          >
            <div>
              <label
                htmlFor="withheldPayslipDates"
                className="block mb-2 font-medium text-gray-900 dark:text-white">
                Select Payslips
              </label>
              <MultiSelect
                onChange={onChangeReleasePayslipDate}
                id="withheldPayslipDates"
                isDisabled={false}
                isMulti={true}
                options={getReleasePayslipModalOptions()} />
            </div>
          </ModalComponent>
        </section>
      )}
    </>
  );
};

export default ViewDetails;
