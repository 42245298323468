import React, { useState, useEffect, useContext } from "react";
import ModalComponent from "../../../../common/Modal/Modal";
import { getShiftById, assignShiftToDepartments } from "../../../../../services/AttendanceService";
import DepartmentDropdown from "../../../../common/DropDown/DepartmentDropdown";
import ModalButtons from "../../../../common/Modal/ModalButtons";
import { btnPrimary } from "../../../../../utils/helper";

const ShiftManagementModal = ({
  isShiftModalOpen,
  handleCloseShiftModal,
  editingShiftId,
  user,
  shifts,
  setShifts,
  createShift,
  updateShift,
}) => {
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [shiftId, setShiftId] = useState(null);
  const [isDefaultShift, setIsDefaultShift] = useState(false);

  const weekDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const weekNumbers = ["All", "1", "2", "3", "4", "5"];
  const ROWS = 7;
  const COLUMNS = 6;

  const [shiftData, setShiftData] = useState({
    shiftName: "",
    shiftStart: "",
    shiftEnd: "",
    isDefaultShift: false,
  });

  const [selectedDays, setSelectedDays] = useState(
    Array(ROWS)
      .fill()
      .map(() => Array(COLUMNS).fill(false))
  );

  // Populate form when editing
  useEffect(() => {
    const fetchShiftDetails = async () => {
      if (editingShiftId) {
        try {
          const shiftDetails = await getShiftById(editingShiftId);
          if (shiftDetails) {
            // Populate shift data
            setShiftData({
              shiftName: shiftDetails.data.shiftName || "",
              shiftStart: shiftDetails.data.shiftStart || "",
              shiftEnd: shiftDetails.data.shiftEnd || "",
              isDefaultShift: shiftDetails.data.isDefault || false,
            });

            // send the department prop to child component
            const existingDepartments = shiftDetails.data.departmentIds || [];
            setSelectedDepartments(existingDepartments);

            // Convert weekends array back to selected days grid
            const newSelectedDays = Array(ROWS)
              .fill()
              .map(() => Array(COLUMNS).fill(false));

            shiftDetails.data.weekends?.forEach(weekend => {
              const dayIndex = weekend.dayOfWeek; // Corrected property name
              const weekIndex = weekend.weekNumber; // Corrected property name
              if (dayIndex >= 0 && dayIndex < ROWS && weekIndex >= 0 && weekIndex < COLUMNS) {
                newSelectedDays[dayIndex][weekIndex] = weekend.isWeekend; // Corrected isWeekend mapping
              }
            });
            // Update "Select All" for each day
            const updatedSelectedDays = newSelectedDays.map(day => {
              const allSelected = day.slice(1).every(Boolean); // Check if all week checkboxes are selected
              day[0] = allSelected; // Update "Select All" checkbox
              return day;
            });
            setSelectedDays(updatedSelectedDays);
          }
        } catch (error) {
          console.error("Error fetching shift details:", error);
        }
      }
    };

    fetchShiftDetails();
  }, [editingShiftId]);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setShiftData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onDaySelect = (dayIndex, weekIndex) => {
    setSelectedDays(prev => {
      const newSelectedDays = [...prev];

      if (weekIndex === 0) {
        // "All" checkbox logic
        const selectAll = !prev[dayIndex][weekIndex];
        newSelectedDays[dayIndex] = new Array(weekNumbers.length).fill(selectAll); // Select or deselect all week numbers
      } else {
        // Individual week numbers logic
        newSelectedDays[dayIndex] = [...prev[dayIndex]];
        newSelectedDays[dayIndex][weekIndex] = !prev[dayIndex][weekIndex];

        // Ensure "All" checkbox reflects the correct state
        const allSelected = newSelectedDays[dayIndex].slice(1).every(Boolean);
        newSelectedDays[dayIndex][0] = allSelected;
      }

      return newSelectedDays;
    });
  };

  const handleSubmit = async () => {
    // Validate inputs
    if (!shiftData.shiftName || !shiftData.shiftStart || !shiftData.shiftEnd) {
      alert("All fields are required!");
      return;
    }

    // Create weekends data
    const weekends = selectedDays
      .flatMap((day, dayIndex) =>
        day.map((isSelected, weekIndex) => {
          if (isSelected) {
            return {
              DayOfWeek: dayIndex,
              WeekNumber: parseInt(weekIndex),
              IsWeekend: true,
            };
          }
          return {
            DayOfWeek: dayIndex,
            WeekNumber: parseInt(weekIndex),
            IsWeekend: false,
          };
        })
      )
      .filter(Boolean);

    const shiftPayload = {
      shiftName: shiftData.shiftName,
      shiftStart: editingShiftId
        ? shiftData.shiftStart // For Edit, use as-is
        : `${shiftData.shiftStart}:00`, // For Add, append ":00"seconds
      shiftEnd: editingShiftId
        ? shiftData.shiftEnd // For Edit, use as-is
        : `${shiftData.shiftEnd}:00`, // For Add, append ":00"seconds

      updatedBy: user.id,
      weekends,
    };

    try {
      if (editingShiftId) {
        // Update existing shift
        const updatedShift = await updateShift({
          id: editingShiftId,
          ...shiftPayload,
        });
        // Update the shift in the state, replacing the old shift with the updated one
        setShifts(prevShifts =>
          prevShifts.map(shift => (shift.id === updatedShift.data.id ? updatedShift.data : shift))
        );
        // If departments are selected, assign the shift to departments else send empty data for delink the shift from department
        const assignShiftData = {
          departmentIds:
            selectedDepartments.length > 0
              ? selectedDepartments.map(dept => (typeof dept === "object" ? dept.id : dept))
              : [],
          shiftId: editingShiftId,
        };
        try {
          const assignmentResponse = await assignShiftToDepartments(assignShiftData);
        } catch (error) {
          console.error("Error assigning shift:", error);
        }
        handleCloseShiftModal();
      } else {
        const createdShift = await createShift(shiftPayload);
        setShifts(prevShifts => [...prevShifts, createdShift]);

        // If departments are selected, assign the shift to departments else send empty data for delink the shift from department
        const assignShiftData = {
          departmentIds:
            selectedDepartments.length > 0
              ? selectedDepartments.map(dept => (typeof dept === "object" ? dept.id : dept))
              : [],
          shiftId: createdShift.id,
        };
        try {
          const assignmentResponse = await assignShiftToDepartments(assignShiftData);
        } catch (error) {
          console.error("Error assigning shift:", error);
        }
        handleCloseShiftModal();
      }
    } catch (error) {
      console.error("Error saving shift:", error);
    }
  };

  const handleCloseModal = () => {
    setShiftData({
      shiftName: "",
      shiftStart: "",
      shiftEnd: "",
      isDefaultShift: false,
    });
    setSelectedDays(
      Array(ROWS)
        .fill()
        .map(() => Array(COLUMNS).fill(false))
    );
    handleCloseShiftModal();
  };

  const handleDepartmentsChange = departmentIds => {
    setSelectedDepartments(departmentIds);
  };

  useEffect(() => {
    setIsDefaultShift(shiftData.isDefaultShift ? true : false);
  }, [shiftData.isDefaultShift]);

  return (
    <ModalComponent
      show={isShiftModalOpen}
      size="xl"
      bodyClassName="gap-4 mt-2"
      heading={editingShiftId ? "Edit Shift" : "Add Shift"}
      showFooter={false}
      onClose={handleCloseModal}>
      <div className="flex flex-col gap-4">
        {/* Shift Name Input */}
        <div>
          <label htmlFor="shiftName" className="block text-sm font-medium text-gray-700">
            Shift Name
          </label>
          <input
            type="text"
            id="shiftName"
            name="shiftName"
            value={shiftData.shiftName}
            onChange={handleInputChange}
            placeholder="Enter shift name"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        {/* Shift Start Time Input */}
        <div>
          <label htmlFor="shiftStart" className="block text-sm font-medium text-gray-700">
            Shift Start Time
          </label>
          <input
            type="time"
            id="shiftStart"
            name="shiftStart"
            value={shiftData.shiftStart}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        {/* Shift End Time Input */}
        <div>
          <label htmlFor="shiftEnd" className="block text-sm font-medium text-gray-700">
            Shift End Time
          </label>
          <input
            type="time"
            id="shiftEnd"
            name="shiftEnd"
            value={shiftData.shiftEnd}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>

        {/* Weekends Selection */}
        <div className="mt-6">
          <label className="block text-sm font-medium text-gray-700 mb-2">Select Weekends</label>
          <div className="border rounded-lg overflow-hidden bg-gray-50">
            {/* Header Row */}
            <div className="grid grid-cols-7 gap-0">
              <div className="p-3 border-b bg-gray-100 col-span-1">
                <span className="text-sm font-medium text-gray-700">Days</span>
              </div>
              <div className="col-span-6">
                <div className="grid grid-cols-6 gap-0 sm:grid-cols-6 md:grid-cols-3 lg:grid-cols-6">
                  {weekNumbers.map((week, idx) => (
                    <div key={week} className="p-3 text-center border-b border-l bg-gray-100">
                      <span className="text-[.8rem] font-medium text-gray-700">
                        {idx === 0 ? week : `Week-${week}`}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Days and Checkboxes */}
            {weekDays.map((day, dayIndex) => (
              <div key={day} className="grid grid-cols-7 gap-0 border-b last:border-b-0">
                <div className="p-3 border-r flex items-center">
                  <span className="text-sm text-gray-700">{day}</span>
                </div>
                <div className="col-span-6">
                  <div className="grid grid-cols-6 gap-0 sm:grid-cols-6 md:grid-cols-3 lg:grid-cols-6">
                    {weekNumbers.map((week, weekIndex) => (
                      <div
                        key={weekIndex}
                        className="flex items-center justify-center border-l p-3">
                        <label className="relative flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            className="rounded border-gray-300 text-blue-500 focus:ring-blue-500 h-4 w-4 cursor-pointer"
                            checked={selectedDays[dayIndex][weekIndex]}
                            onChange={() => onDaySelect(dayIndex, weekIndex)}
                          />
                          <span className="sr-only">{`${day} ${weekIndex}`}</span>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Dropdown for Shifts Applicable To */}
        {!isDefaultShift && (
          <div className="flex flex-col space-y-2 w-full">
            <label htmlFor="applicableTo" className="text-sm font-medium text-gray-700">
              Applicable To
            </label>
            <div className="w-full">
              <DepartmentDropdown
                selectedDepartments={selectedDepartments}
                onDepartmentsChange={handleDepartmentsChange}
                className="w-full" // Ensure the dropdown takes the full width
              />
            </div>
          </div>
        )}
        {/* Submit Button */}
        <ModalButtons
          btnCont="flex justify-end gap-2"
          cancelBtnText="Cancel"
          actionBtnText={editingShiftId ? "Update" : "Submit"}
          onClickCancel={handleCloseModal}
          onClickAction={handleSubmit}
          actionBtnType="button"
          actionBtnClass={`${btnPrimary()} rounded-md text-white font-semibold  px-4 py-[10px] tracking-wide`}
          cancelBtnClass="gray"
        />
      </div>
    </ModalComponent>
  );
};

export default ShiftManagementModal;
