import { useEffect, useId } from "react";
import { createUID } from "../helper";
import MandatorySign from "../../common/Input/MandatorySign";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { getLatestEmployeeNumber, IsValidEmployeeNumber } from "../../../services/UserService";
import { setIsValidEmployeeNumber, setLatestEmployeeNumber } from "../../../store/slices/userManagementSlice";
import SelectWithDropdown from "../../common/SelectWithDropdown/SelectWithDropdown";
import { bloodGroupOptions, genderOptions, getBloodGroup, getGender, getMaritalStatus, maritalOptions } from "./helper";
import { setSelectedGender } from "../../../store/slices/genderSelectSlice";
import { setSelectedmaritalStatus } from "../../../store/slices/maritalStatusSelectSlice";
import { setSelectedbloodGroup } from "../../../store/slices/bloodGroupSelectSlice";
import "./GeneralInfo.css";
import { MaritalStatusEnum } from "../../../enum/MaritalStatusEnum";

/**
 * Renders the general info form
 *
 * @param {{handleUserChange: function}} props - The properties for rendering the general info form.
 * @param {function} props.handleUserChange - The function to handle user changes.
 * @return {JSX.Element} The general info form component.
 */
const GeneralInfo = ({ handleUserChange }) => {
  const UID = useId();
  const dispatch = useAppDispatch();
  const umState = useAppSelector(state => state.userManagement);
  const user = useAppSelector(state => state.userManagement.user);
  
  // Get gender, marital_status and blood_group from helper functions created
  const gender = getGender(user);
  const marital_status = getMaritalStatus(user);
  const blood_group = getBloodGroup(user);

  const selectedGender = useAppSelector(state => state.genderSelect.selectedGender?.label) || gender;
  const selectedMaritalStatus = useAppSelector(state => state.maritalStatusSelect.selectedmaritalStatus?.label) || marital_status;
  const selectedBloodGroup = useAppSelector(state => state.bloodGroupSelect.selectedbloodGroup?.label) || blood_group;

  useEffect(() => {
    fetchLatestEmployeeNumber();
  }, [umState.renderEmployeeNumber]);

  const fetchLatestEmployeeNumber = async () => {
    const res = await getLatestEmployeeNumber();
    dispatch(setLatestEmployeeNumber(res.data));
  }

  const onEmployeeNumberBlur = async () => {
    if (user.employeeNumber) {
      const isValidNumber = await IsValidEmployeeNumber(user.employeeNumber, user.id);
      dispatch(setIsValidEmployeeNumber(isValidNumber.data));
    }
  };
  
  const isActive = umState.selectedUserModalItem === "generalInformation";

  const handleGender = (_ , selectedGender) => {
    const updatedGender = { ...user, gender: selectedGender.value };
    dispatch(setSelectedGender(selectedGender));
    handleUserChange({ target: { name: "gender", value: updatedGender.gender } });
  }

  const handleMaritalStatus = (_ , selectedMaritalStatus) => {
    const updatedMaritalStatus = { ...user, maritalStatus: selectedMaritalStatus.value };
    dispatch(setSelectedmaritalStatus(selectedMaritalStatus));
    handleUserChange({ target: { name: "maritalStatus", value: updatedMaritalStatus.maritalStatus } });
  }

  const handleBloodGroupStatus = (_ , selectedBloodGroupStatus) => {
    const updatedUser = { ...user, bloodGroup: selectedBloodGroupStatus.value };
    dispatch(setSelectedbloodGroup(selectedBloodGroupStatus));
    handleUserChange({ target: { name: "bloodGroup", value: updatedUser.bloodGroup } });
  }

  return (
    <>
      {isActive && (
        <div className="grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-8">
          <div>
            <label
              htmlFor="employee-Number"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Employee Number <MandatorySign />
            </label>
            <input
              type="text"
              id="employeeNumber"
              name="employeeNumber"
              aria-describedby="helper-text-explanation"
              className={`bg-red-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500`}
              onChange={handleUserChange}
              onBlur={onEmployeeNumberBlur}
              placeholder="Example: TC001"
              value={user?.employeeNumber ?? ""}
            />

            {!umState.isValidEmployeeNumber && (
              <p className="text-red-600 mt-1 text-xs">Employee number already exists!</p>
            )}

            {umState.isValidEmployeeNumber && (
              <p className="text-600 mt-1 text-xs">
                Last Employee Number: {umState.latestEmployeeNumber}
              </p>
            )}
          </div>
          {/* First Name */}
          <div>
            <label
              htmlFor={createUID(UID, "first-name")}
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              First Name <MandatorySign />
            </label>

            <input
              type="text"
              name="firstName"
              id={createUID(UID, "first-name")}
              value={user?.firstName ?? ""}
              onChange={handleUserChange}
              className="bg-red-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="First Name"
              required
            />
          </div>

          {/* Middle Name */}
          <div>
              <label
                htmlFor="middle-name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Middle Name
              </label>
              <input
                type="text"
                name="middleName"
                id="middle-name"
                value={user?.middleName ?? ""}
                onChange={handleUserChange}
                className="border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                placeholder="Middle Name"
              />
            </div>

          {/* Last Name */}
            <div>
              <label
                htmlFor="last-name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Last Name <MandatorySign />
              </label>
              <input
                type="text"
                name="lastName"
                id="last-name"
                value={user?.lastName ?? ""}
                onChange={handleUserChange}
                className="bg-red-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                placeholder="Last Name"
                required=""
              />
            </div>

          {/* Work Email */}
            <div>
              <label
                htmlFor="user-work-email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Work Email <MandatorySign />
              </label>
              <input
                type="email"
                name="email"
                id="user-work-email"
                value={user?.email ?? ""}
                onChange={handleUserChange}
                className="bg-red-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                placeholder="name@company.com"
                required=""
              />
            </div>

          {/* Personal Email */}
            <div>
              <label
                htmlFor="user-personal-email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Personal Email
              </label>
              <input
                type="email"
                name="personalEmail"
                id="user-personal-email"
                value={user?.personalEmail ?? ""}
                onChange={handleUserChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                placeholder="name@gamil.com"
                required=""
              />
            </div>

            {/* Gender */}
            <div>
              <label
                htmlFor="gender"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Gender
              </label>
              <SelectWithDropdown options={genderOptions(user)} handleChange={handleGender} defaultValue={selectedGender} isDefaultValue={true} backgroundColor="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full h-[42px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500" size="sm" className="flex justify-between" />
            </div>

          {/* Mobile Number */}
            <div>
              <label
                htmlFor="mobile-number"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Mobile Number
              </label>
              <input
                type="number"
                name="phoneNumber"
                id="mobile-number"
                value={user?.phoneNumber ?? ""}
                onChange={handleUserChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                placeholder="Add mobile number"
                required=""
              />
            </div>

          {/* Emergency Mobile Number */}
            <div>
              <label
                htmlFor="emergency-mobile-number"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Emergency Mobile Number
              </label>
              <input
                type="number"
                name="emergencyContact"
                id="emergency-mobile-number"
                value={user?.emergencyContact ?? ""}
                onChange={handleUserChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                placeholder="Add emergency mobile number"
                required=""
              />
            </div>

          {/* Date Of Birth */}
            <div>
              <label
                htmlFor="date-of-birth"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                D.O.B <MandatorySign />
              </label>
              <input
                type="Date"
                name="dateOfBirth"
                id="date-of-birth"
                value={user?.dateOfBirth !== null ? user?.dateOfBirth.slice(0, 10) : ""}
                onChange={handleUserChange}
                className="bg-red-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full h-[42px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                placeholder="Date of birth"
              />
            </div>

            {/* Marital Status */}
            <div>
              <label
                htmlFor="marital_status"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Marital Status
              </label>
              <SelectWithDropdown id={"marital_status"} options={maritalOptions(user)} handleChange={handleMaritalStatus} defaultValue={selectedMaritalStatus} isDefaultValue={true} backgroundColor="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full h-[42px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500" size="sm" className="flex justify-between" />
            </div>

            {/* Marriage Date */}
            {user?.maritalStatus === MaritalStatusEnum.Married && 
            <><div>
              <label
                htmlFor="marriage_date"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Marriage Date
              </label>
              <input
                type="Date"
                name="marriageDate"
                id="marriage_date"
                value={user?.marriageDate !== null ? user?.marriageDate?.slice(0, 10) : ""}
                onChange={handleUserChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full h-[42px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                placeholder="Marriage Date"
              />
            </div>

             {/* Spouse Name */}
            <div>
              <label
                htmlFor="spouse-name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Spouse Name
              </label>
              <input
                type="text"
                name="spouseName"
                id="spouse-name"
                value={user?.spouseName ?? ""}
                onChange={handleUserChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                placeholder="Spouse Name"
              />
            </div>
            </>
            }

            {/* Blood Group */}
            <div>
              <label
                htmlFor="blood_group"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Blood Group
              </label>
              <SelectWithDropdown id={"blood_group"} options={bloodGroupOptions(user)} handleChange={handleBloodGroupStatus} defaultValue={selectedBloodGroup} isDefaultValue={true} backgroundColor="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full h-[42px] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500" size="sm" className="flex justify-between" />
            </div>

          {/* Address */}
            <div className="col-span-2">
              <label
                htmlFor="user-address"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Address
              </label>
              <textarea
                type="tex"
                name="address"
                id="user-address"
                value={user?.address ?? ""}
                onChange={handleUserChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
                placeholder="Address"
              />
            </div>
        </div>
      )}
    </>
  );
};

export default GeneralInfo;
