import React from "react";
import { Button } from "flowbite-react";
import { btnPrimary } from "../../../utils/helper";
import { FaUserGroup } from "react-icons/fa6";
import { showToast } from "../Toast";

interface FileDownloaderProps {
  fetchAPI: () => Promise<{ status: number; data: any }>;
  btnText: string;
  fileName?: string;
  fileType?: string;
  warningMsg: string;
  errorMsg: string;
  btnClassName?: string;
  useDefaultBtnIcon?: boolean;
  btnIcon?: React.ReactNode;
  useBtnPrimary?: boolean;
}

const FileDownloader: React.FC<FileDownloaderProps> =  (props) => {
  const defaultBtnIcon = (
    <div className="flex mr-2">
      <FaUserGroup />
    </div>
  );

  const downloadFile = async () => {
    try {
      const response = await props.fetchAPI();

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: props.fileType || "application/octet-stream" });
        const fileUrl = window.URL.createObjectURL(blob);

        // Create a temporary <a> element to trigger the download
        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", props.fileName || "file"); // Default file name if not provided
        document.body.appendChild(link);
        link.click();

        // Cleanup
        link.remove();
        window.URL.revokeObjectURL(fileUrl);
      } else {
        return showToast("warn", props.warningMsg, { duration: 5000 });
      }
    } catch (error) {
      return showToast("error", props.errorMsg, { duration: 5000 });
    }
  };

  return (
    <Button
      onClick={() => downloadFile()}
      className={`${props.useBtnPrimary ? btnPrimary() : ""} ${props.btnClassName}`}
    >
      {props.useDefaultBtnIcon ? defaultBtnIcon : props.btnIcon}
      <span>{props.btnText}</span>
    </Button>
  );
};

export default FileDownloader;