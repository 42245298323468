import React, { useState, useRef, useEffect } from "react";
import { Button } from "flowbite-react";
import { FaChevronDown } from "react-icons/fa6";
import { showToast } from "../common/Toast";
import ModalComponent from "../common/Modal/Modal";

import { importUsersCsv } from "../../services/UserService";
import FileDownloader from "../common/FileDownloader/FileDownloader";
import { getImportCsvSample } from "../../services/FileUploadService";
import { btnPrimary } from "../../utils/helper";
import ProfileSvg from "../../media/ProfileSvg";
import { FaUserGroup } from "react-icons/fa6";
import DragAndDrop from "../common/FileUpload/DragAndDrop";
import ImportSummaryReport from "./ImportSummaryReport";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { getCustomFields } from "../../services/InvoiceService";
import {
  setIsAddingRestrictedUser,
  setIsEditingUser,
  setRefetchUsers,
  setToggleUserModal,
} from "../../store/slices/userManagementSlice";

export const EmployeeManagementButtons = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const dispatch = useAppDispatch();
  const umState = useAppSelector(state => state.userManagement);

  const [toggleImportUsers, setToggleImportUsers] = useState(false);
  const [isRestricted, setIsRestricted] = useState(false);

  const [fileName, setFileName] = useState(null);

  const [importSummary, setImportSummary] = useState(null);

  const [importFile, setImportFile] = useState(null);

  // Function to handle file change
  const handleUserCsvFileChange = async event => {
    const file = event.target.files[0];
    if (file?.type === "text/csv") {
      setFileName(file.name);
      setImportFile(file);
    } else {
      return showToast("warning", "Please upload valid CSV file!");
    }
  };

  const handleImport = async () => {
    try {
      const importSummary = await importUsersCsv(importFile, isRestricted);
      setImportSummary(importSummary.data);
    } catch (error) {
      return showToast("warn", "Error importing users");
    }
  };

  //set states to initial states
  const resetStates = () => {
    dispatch(setRefetchUsers(!umState.refetchUsers));
    setToggleImportUsers(false);
    setImportFile(null);
    setFileName("");
    setImportSummary(null);
  };

  const onRestrictedToggleChange = () => {
    setIsRestricted(!isRestricted);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setToggleImportUsers(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setToggleImportUsers(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <>
      {/*  Import Users Modal */}
      <ModalComponent
        show={toggleImportUsers}
        size="5xl"
        bodyClassName="mt-2"
        heading="Import Users"
        showFooter={true}
        onClose={resetStates}
        footer={
          <div className="flex justify-between w-full">
            {/* Download CSV Sample button */}
            <FileDownloader
              fetchAPI={getImportCsvSample}
              fileType="text/csv"
              fileName="ImportCsvSample.csv"
              btnText={"Download CSV Sample"}
              warningMsg={"Unable to download CSV. Please Retry!"}
              errorMsg={"Error downloading CSV."}
            />

            <div>
              {!importSummary && (
                <Button
                  disabled={!fileName}
                  onClick={handleImport}
                  className={`${btnPrimary()} px-0 ml-auto flex item-center justify-center`}>
                  <span>Import</span>
                </Button>
              )}
            </div>
          </div>
        }>
        {importSummary && (
          <div>
            <ImportSummaryReport importSummaryReport={importSummary} />
          </div>
        )}
        {!importSummary && (
          <>
            <div className="h-fit grid grid-cols-2 gap-3 ">
              <div>
                <DragAndDrop
                  types={["csv"]} // Accept only CSV files
                  value={fileName ?? ""} // File name to display when uploaded
                  boldMessage="Drop your user CSV file here"
                  normalMessage="or click to select a CSV file"
                  onChange={handleUserCsvFileChange}
                  onDragOver={e => e.preventDefault()}
                  onDrop={e => e.preventDefault()}
                />

                <div className="flex  mt-10">
                  <span className="ms-3 text-sm text-gray-900 dark:text-gray-300 ml-4">
                    <p className="font-bold">Restricted Login</p>
                    <p className="text-xs">
                      Users that cannot login <br /> but can be part of a payroll.
                    </p>
                  </span>
                  <span>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value=""
                        checked={!isRestricted}
                        onChange={onRestrictedToggleChange.bind()}
                        className="sr-only peer"
                      />
                      <div
                        className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-thynkwebPrimary-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-thynkwebPrimary-800`}></div>
                    </label>
                  </span>
                  <span className="ms-3 text-sm text-gray-900 dark:text-gray-300 ml-4">
                    <p className="font-bold">Invite</p>
                    <p className="text-xs">Users that can login.</p>
                  </span>
                </div>
              </div>

              <div>
                <ol className="list-decimal pl-4 space-y-4">
                  <li className="text-gray-700">
                    <span className="font-semibold">
                      Required fields: EmployeeNumber, FirstName, Email, DateOfJoining, AnnualCTC,
                      RegimeType.
                    </span>
                  </li>
                  <li className="text-gray-700">
                    Values should be separated by a <span className="font-semibold">comma (,)</span>
                    . If a field's value contains a comma, enclose the entire field in double
                    quotes.
                  </li>
                  <li className="text-gray-700">
                    Email and employee number must be unique for each user.
                  </li>
                  <li className="text-gray-700">
                    Empty fields are represented by consecutive commas (,,).
                  </li>
                  <li className="text-gray-700">
                    Email fields should contain valid email formats.
                  </li>
                  <li className="text-gray-700">Dates should be in the format YYYY-MM-DD.</li>
                  <li className="text-gray-700">
                    Gender, BloodGroup, MaritalStatus, and RegimeType must match one of the
                    following enum:
                    <ul className="list-disc pl-4 space-y-2">
                      <li>
                        <span className="font-semibold">Gender</span>: Male, Female,
                        PreferNotToDisclose, Others.
                      </li>
                      <li>
                        <span className="font-semibold">BloodGroup</span>: NotAvailable, OPositive,
                        APositive, BPositive, ABPositive, ABNegative, ANegative, BNegative,
                        ONegative.
                      </li>
                      <li>
                        <span className="font-semibold">MaritalStatus</span>: NotAvailable, Married,
                        Unmarried.
                      </li>
                      <li>
                        <span className="font-semibold">RegimeType</span>: New, Old.
                      </li>
                    </ul>
                  </li>
                  <li className="text-gray-700">
                    Numeric fields should only contain numeric values.
                  </li>
                </ol>
              </div>
            </div>
          </>
        )}
      </ModalComponent>

      <div className="relative inline-block text-left" ref={dropdownRef}>
        <div>
          <button
            type="button"
            className={`${btnPrimary()} inline-flex items-center p-2 gap-2 rounded-md`}
            id="user-menu-button"
            aria-expanded={isOpen}
            aria-haspopup="true"
            onClick={toggleDropdown}>
            User Actions
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5">
              <path
                fillRule="evenodd"
                d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
                clipRule="evenodd"
              />
              <path d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z" />
            </svg>{" "}
          </button>
        </div>

        {isOpen && (
          <div
            className="z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabIndex="-1">
            <div className="py-1" role="none">
              <button
                className="text-gray-700 block px-4 py-2 text-sm w-full text-left hover:bg-gray-100 flex items-center"
                role="menuitem"
                tabIndex="-1"
                onClick={() => {
                  setIsOpen(false);
                  setToggleImportUsers(true);
                }}>
                <FaUserGroup className="mr-3" />
                Import Users
              </button>
              <button
                className="text-gray-700 block px-4 py-2 text-sm w-full text-left hover:bg-gray-100 flex items-center"
                role="menuitem"
                tabIndex="-1"
                onClick={() => {
                  setIsOpen(false);
                  dispatch(setToggleUserModal(true));
                  dispatch(setIsEditingUser(false));
                  dispatch(setIsAddingRestrictedUser(true));
                }}>
                <ProfileSvg className="w-4 h-4 mr-3 fill-current" />
                Add User
              </button>
              <button
                className="text-gray-700 block px-4 py-2 text-sm w-full text-left hover:bg-gray-100 flex items-center"
                role="menuitem"
                tabIndex="-1"
                onClick={() => {
                  setIsOpen(false);
                  dispatch(setToggleUserModal(true));
                  dispatch(setIsEditingUser(false));
                }}>
                <ProfileSvg className="w-4 h-4 mr-3 fill-current" />
                Invite User
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
