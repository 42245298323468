import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface bloodGroupState {
  selectedbloodGroup: string | null; // `string` for bloodGroup values, or `null` if not selected
}

const initialState: bloodGroupState = {
  selectedbloodGroup: null, // initially no bloodGroup selected
};

const bloodGroupSelectSlice = createSlice({
  name: 'bloodGroup',
  initialState,
  reducers: {
    setSelectedbloodGroup: (state, action: PayloadAction<string | null>) => {
      state.selectedbloodGroup = action.payload;
    },
  },
});

export const { setSelectedbloodGroup } = bloodGroupSelectSlice.actions;

export const selectSelectedbloodGroup = (state: { bloodGroup: bloodGroupState }) => state.bloodGroup ? state.bloodGroup.selectedbloodGroup : null; // Safe fallback

export default bloodGroupSelectSlice.reducer;
