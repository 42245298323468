import { useState, useEffect } from "react";
import { GetUserById } from "../../../services/UserService";
import RatingBar from "../../common/RatingBar";
import DeleteSvg from "./../../../media/DeleteSvg";
import moment from "moment";

/**
 * Renders a single row in the Skills table.
 *
 * @param {object} createdBy - The creator of the skill.
 * @param {object} updatedBy - The last user who updated the skill.
 * @param {string} lastWorkedOn - The date when the skill was last worked on.
 * @param {string} dateUpdated - The date when the skill was last updated.
 * @param {string} name - The name of the skill.
 * @param {number} strength - The strength level of the skill.
 * @param {number} skillId - The ID of the skill.
 * @param {boolean} isProfile - Indicates if the skill is part of the user profile.
 * @return {JSX.Element} - The rendered row in the Skills table.
 */
const SkillsRows = ({
  createdBy,
  updatedBy,
  lastWorkedOn,
  dateUpdated,
  name,
  strength,
  skillId,
  isProfile,
}) => {
  const [addedBy, setAddedBy] = useState(createdBy);
  const [updateBy, setUpdatedBy] = useState(updatedBy);
  useEffect(() => {
    GetUser(createdBy).then(res => setAddedBy(res));
    if (updatedBy === 0) setUpdatedBy("");
    else
      GetUser(updatedBy).then(res => {
        setUpdatedBy(res);
      });
  }, []);
  
  // If dateUpdated is the default placeholder value, use lastWorkedOn instead.
  const normalizedDateUpdated = dateUpdated === "0001-01-01T00:00:00" ? lastWorkedOn : dateUpdated;

  const lastWorkedOnFormatted = moment(lastWorkedOn).format("YYYY-MM");
  const updatedDateFormatted = moment(normalizedDateUpdated).format("YYYY-MM-DD");

  //max strength bars will be 5
  const StrengthBar = ({ strength }) =>
    Array.from(Array(5)).map((_, i) => (
      <RatingBar key={i} className={strength >= i + 1 ? "bg-thynkwebPrimary-800" : "bg-gray-200"} />
    ));

  return (
    <tr
      className="border-b border-gray-200 cursor-pointer"
      key={skillId}
      data-id={JSON.stringify([skillId, "update"])}>
      <td className="pl-4 py-3 !w-[15%]">{name}</td>
      <td className="pl-4 py-3 !w-[15%]">{lastWorkedOnFormatted}</td>
      {!isProfile && (
        <>
          <td className="pl-4 py-3 !w-[15%]">{addedBy}</td>
          <td className="pl-4 py-3 !w-[15%]">{updateBy}</td>
        </>
      )}
      <td className="pl-4 py-3 !w-[15%]">{updatedDateFormatted}</td>
      <td className="pl-4 py-3 !w-[25%]">
        <div className="flex items-center gap-1">
          <StrengthBar strength={strength} />
        </div>
      </td>
      <td className="pl-4 py-3" data-id={JSON.stringify([skillId, "delete"])}>
        <div className={`mx-auto ${isProfile ? "text-left" : "grid place-items-center"}`}>
          <button className=" p-2 rounded-md hover:bg-red-100">
            <DeleteSvg className="!fill-red-800 " />
          </button>
        </div>
      </td>
    </tr>
  );
};

export default SkillsRows;

const GetUser = async id => {
  if (!id) return;
  const res = await GetUserById(id);
  const data = await res.data;
  return `${data.firstName} ${data.lastName}`;
};
