import { useState, useRef, useEffect, useContext } from "react";
import AsyncSelect from "react-select/async";
import debounce from "lodash.debounce";

import { showToast } from "../../common/Toast";
import MandatorySign from "../../common/Input/MandatorySign";

import { GetUserByParam, GetUserFields } from "../../../services/UserService";
import { GetDepartments } from "../../../services/DepartmentService";
import { customStyles } from "../../Settings/helper/helper";
import { Dropdown } from "primereact/dropdown";
import { IoMdArrowDropleft } from "react-icons/io";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { setEmploymentTypeWithOptions, setUser } from "../../../store/slices/userManagementSlice";

/**
 * Renders the employment details form
 *
 * @param {{handleUserChange: function}} props - The properties for rendering the employment details form.
 * @param {function} props.handleUserChange - The function to handle user changes.
 * @return {JSX.Element} The employment details form component.
 */
const EmploymentDetails = ({ handleUserChange }) => {
  const dispatch = useAppDispatch();
  const umState = useAppSelector(state => state.userManagement);
  const user = useAppSelector(state => state.userManagement.user);

  const employmentType = useAppSelector(state => state.userManagement.employmentTypeWithOptions);

  const isActive = umState.selectedUserModalItem === "employmentDetails";

  const [selectedManager, setSelectedManager] = useState([]);
  // Initial value of Async select
  const [inputValue, setInputValue] = useState("");

  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    setReportingManagerValue();
  }, []);

  useEffect(() => {
    getEmploymentType();
  }, []);
  useEffect(() => {
    fetchDepartments();
  }, []);

  /**
   * Handles the change event when the selectedValue of the manager changes.
   *
   * @param {[{value: number, label: string}]} selectedValue - The new selected value of the manager.
   * @return {void | object} The updated user object after changing the reporting manager.
   */
  const handleManagerChange = selectedValue => {
    if (selectedValue.length > 1)
      return showToast("warn", "Only one manager can be selected for the user");

    setSelectedManager(selectedValue);
    dispatch(
      setUser({
        ...umState.user,
        reportingManagerId: selectedValue.length > 0 ? selectedValue[0].value : 0,
      })
    );
  };

  // Function to handle the promise of the async select to show values in dropdown
  const debouncedPromiseOptions = useRef(
    debounce((inputValue, callback) => {
      if (inputValue) {
        GetUserByParam(inputValue).then(response => {
          //user cannot be reporting manager of themselves
          const filteredData = response.data.filter(u => u.id !== user?.id);
          const options = filteredData.map(user => ({
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          }));
          callback(options);
          return options;
        });
      } else {
        callback([]);
      }
    }, 300)
  ).current;

  /**
   * Set the value of the reporting manager.
   *
   * @returns {Array} An empty array.
   */
  const setReportingManagerValue = () => {
    if (user?.reportingManager) {
      const { firstName, lastName } = user.reportingManager;
      const selectedReportingManager = [
        {
          value: user.reportingManagerId,
          label: `${firstName} ${lastName}`,
        },
      ];

      setSelectedManager(selectedReportingManager);
    }
    return [];
  };

  const getEmploymentType = async () => {
    const employmentTypeWithOptions = await GetUserFields().then(res => {
      return res.data.find(uf => uf.name === "Employment Type");
    });

    dispatch(setEmploymentTypeWithOptions(employmentTypeWithOptions));
  };

  const getEmploymentTypeDropdownOptions = () => {
    const options = employmentType?.userFieldOptions.map(option => {
      return {
        value: option.id,
        name: option.optionName,
        itemClassName: "border border-red-700",
      };
    });
    return options ?? [];
  };

  const handleEmploymentTypeChange = event => {
    const optionId = event.value;
    const userFieldValueId =
      user.userFieldValues.find(ufv => ufv.userFieldId === employmentType.id)?.id ?? 0;
    const updatedUserFieldValues = [
      {
        id: userFieldValueId,
        userId: user.id,
        userFieldId: employmentType.id,
        optionId: optionId,
        fieldValue: null,
      },
    ];

    dispatch(
      setUser({
        ...umState.user,
        userFieldValues: updatedUserFieldValues,
      })
    );
  };

  const getEmploymentTypeSelectedOptionId = () => {
    const val =
      user.userFieldValues?.find(ufv => ufv.userFieldId === employmentType.id)?.optionId ?? 0;
    return val;
  };

  const fetchDepartments = async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const response = await GetDepartments(true, page, 10);
      const departmentsArray = response?.data || [];

      const newDepartments = departmentsArray.map(dept => ({
        value: dept.id,
        label: dept.name,
      }));
   

      setDepartments(prevDepartments => {
        const allDepartments = [...prevDepartments, ...newDepartments];
        const uniqueDepartments = allDepartments.filter(
          (dept, index, self) => self.findIndex(d => d.value === dept.value) === index
        );
        return uniqueDepartments;
      });(prevDepartments => [...prevDepartments, ...newDepartments]);
      setPage(prevPage => prevPage + 1);
      setHasMore(newDepartments.length === 10);
    } catch (error) {
      console.error("Error fetching departments:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDepartmentChange = event => {
    const departmentId = event.value;
    const departmentName = departments.find(dept => dept.value === departmentId)?.label || "";
    dispatch(
      setUser({
        ...user,
        departmentId: departmentId,
        department: departmentName,
      })
    );
  };

  const handleScroll = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollHeight - scrollTop === clientHeight) {
      fetchDepartments();
    }
  };

  return (
    <>
      {isActive && (
        <div className="grid gap-4 mb-4 md:gap-6 md:grid-cols-2 sm:mb-10">
          {/* Date of joining */}
          <div>
            <label
              htmlFor="contract-date"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Date of joining <MandatorySign />
            </label>
            <input
              type="Date"
              name="dateOfJoining"
              id="contract-date"
              value={user?.dateOfJoining !== null ? user?.dateOfJoining.slice(0, 10) : ""}
              onChange={handleUserChange}
              className="bg-red-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="Date of contract"
            />
          </div>

          {/* Experience */}
          <div>
            <label
              htmlFor="experience"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Experience before joining (Years) <MandatorySign />
            </label>
            <input
              type="number"
              name="experience"
              id="experience"
              value={user?.experience ?? ""}
              onChange={handleUserChange}
              className="bg-red-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="Example: 1.5"
            />
          </div>

          {/* Confirmation Date */}
          <div>
            <label
              htmlFor="confirmation-date"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Date of confirmation
            </label>
            <input
              type="Date"
              name="dateOfConfirmation"
              id="confirmation-date"
              value={user?.dateOfConfirmation !== null ? user?.dateOfConfirmation.slice(0, 10) : ""}
              onChange={handleUserChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="Date of contract"
            />
          </div>

          {/* Department Dropdown */}
          <div>
            <label
              htmlFor="department-id"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Department
            </label>
            <Dropdown
              value={user?.departmentId}
              options={departments}
              onChange={handleDepartmentChange}
              placeholder="Select Department"
              className="w-full p-2.5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              virtualScrollerOptions={{
                itemSize: 38,
                lazy: true,
                onLazyLoad: fetchDepartments,
                loading: loading,
                showLoader: true,
                loadingTemplate: options => {
                  return (
                    <div className="flex align-items-center p-2" style={{ height: "38px" }}>
                      <span>Loading...</span>
                    </div>
                  );
                },
              }}
            />
          </div>
          {/* Designation*/}
          <div>
            <label
              htmlFor="designation-id"
              className="inline-flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Designation
            </label>
            <input
              type="text"
              name="designation"
              id="designation-id"
              value={user?.designation ?? ""}
              onChange={handleUserChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="Designation"
            />
          </div>

          {/* WorkLocation*/}
          <div>
            <label
              htmlFor="work-location-id"
              className="inline-flex items-center mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Work Location
            </label>
            <input
              type="text"
              name="workLocation"
              id="work-location-id"
              value={user?.workLocation ?? ""}
              onChange={handleUserChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="Work location"
            />
          </div>

          {/* ESI Number */}
          <div>
            <label
              htmlFor="esi-number"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              ESI Number
            </label>
            <input
              type="number"
              name="esiNumber"
              id="esi-number"
              value={user?.esiNumber ?? ""}
              onChange={handleUserChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="ESI number"
            />
          </div>

          {/* PF Number */}
          <div>
            <label
              htmlFor="pf-number"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              PF Number
            </label>
            <input
              type="number"
              name="pfNumber"
              id="pf-number"
              value={user?.pfNumber ?? ""}
              onChange={handleUserChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="PF number"
            />
          </div>

          {/* UAN Number */}
          <div>
            <label
              htmlFor="uan-number"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              UAN (Universal Account Number)
            </label>
            <input
              type="number"
              name="uan"
              id="uan-number"
              value={user?.uan ?? ""}
              onChange={handleUserChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500"
              placeholder="Universal account no."
            />
          </div>

          {/* Reporting Manager Id */}
          <div className="tc-async-select">
            <label
              htmlFor="reporting-manager"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white cursor-pointer">
              Reporting Manager
            </label>

            {selectedManager.length > 0 && (
              <AsyncSelect
                cacheOptions
                defaultOptions
                isMulti={true}
                id="reporting-manager"
                value={selectedManager}
                placeholder="Reporting manager"
                loadOptions={debouncedPromiseOptions}
                onChange={handleManagerChange}
                onInputChange={value => setInputValue(value)}
                noOptionsMessage={() => (inputValue ? "No options" : null)}
                closeMenuOnSelect={true}
                isSearchable={false}
                filterOption={() => true}
                menuIsOpen={!!inputValue}
                isClearable={false}
                styles={customStyles()}
              />
            )}
            {selectedManager.length === 0 && (
              <AsyncSelect
                cacheOptions
                defaultOptions
                isMulti={true}
                id="reporting-manager"
                value={selectedManager}
                placeholder="Reporting manager"
                loadOptions={debouncedPromiseOptions}
                onChange={handleManagerChange}
                onInputChange={value => setInputValue(value)}
                noOptionsMessage={() => (inputValue ? "No options" : null)}
                closeMenuOnSelect={true}
                isSearchable={true}
                filterOption={() => true}
                menuIsOpen={!!inputValue}
                isClearable={false}
                styles={customStyles()}
              />
            )}
          </div>

          {/* Employment type list */}
          <div className="">
            <label
              htmlFor="employment-type"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Employment Type
            </label>
            <div className="mt-2">
              <div className="card flex justify-content-center">
                <Dropdown
                  value={getEmploymentTypeSelectedOptionId()}
                  onChange={handleEmploymentTypeChange}
                  options={getEmploymentTypeDropdownOptions()}
                  optionLabel="name"
                  placeholder="Select type"
                  filter
                  className="border w-full bg-gray-50 rounded-lg border-gray-300"
                />
                <div className=" flex justify-end items-center  ">
                  <IoMdArrowDropleft size={28} className="-mr-[0.7rem]" color="#eef7ff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EmploymentDetails;
