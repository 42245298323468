import React, { useState, useEffect } from "react";
import ModalComponent from "../../../../common/Modal/Modal";
import ModalButtons from "../../../../common/Modal/ModalButtons";
import { btnPrimary } from "../../../../../utils/helper";
import { GetUsers } from "../../../../../services/UserService";
import {
  getShiftsAvailableForDepartment,
  assignShiftToEmployees,
} from "../../../../../services/AttendanceService";
import { showToast } from "../../../../common/Toast";

const AssignShiftModal = ({
  isShiftModalOpen,
  handleCloseModal,
  handleSubmit,
  handleInputChange,
  shiftData,
}) => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]); // Updated state for multi-select
  const [shifts, setShifts] = useState([]); // State to hold shift options
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [isPastDate, setIsPastDate] = useState(false); // State to check if start date is in the past or today
  const [updateAttendance, setUpdateAttendance] = useState(false); // State to manage the checkbox

  // Handle multi-select employee changes
  const handleUserInputChange = e => {
    const selectedOptions = Array.from(e.target.selectedOptions)
      .map(opt => {
        if (opt.value === "") {
          return null; // If value is empty, return null
        }
        return {
          id: Number(opt.value),
          departmentId: employees.find(emp => emp.id === Number(opt.value))?.departmentId,
        };
      })
      .filter(Boolean); // Remove any null values from the array

    setSelectedEmployees(selectedOptions);

    // Set selectedDepartmentId to null if no valid selection is made
    if (selectedOptions.length > 0) {
      setSelectedDepartmentId(selectedOptions[0].departmentId);
    } else {
      setSelectedDepartmentId(null);
    }
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await GetUsers(10, 1); // Fetch first 10 employees by default
        setEmployees(response.data || []);
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, []);

  // Fetch available shifts
  const fetchShifts = async () => {
    if (!selectedDepartmentId) {
      return;
    }
    try {
      const response = await getShiftsAvailableForDepartment(selectedDepartmentId); // Replace with departmentId
      setShifts(response.data);
    } catch (error) {
      console.error("Error fetching shifts:", error);
    }
  };

  const handleDateChange = e => {
    const { value } = e.target;
    handleInputChange(e); // Ensure the parent handles this as well
    const selectedDate = new Date(value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Set current date to 00:00 for comparison
    selectedDate.setHours(0, 0, 0, 0); // Also set selected date to 00:00 for a fair comparison

    // Check if selected date is today or in the past
    if (selectedDate.getTime() <= currentDate.getTime()) {
      setIsPastDate(true); // Mark if it's a past date or today
      setUpdateAttendance(true); // Pre-check
    } else {
      setIsPastDate(false);
      setUpdateAttendance(false); // Uncheck if the date is in the future
    }
  };

  const handleCheckboxChange = e => {
    setUpdateAttendance(e.target.checked);
  };

  handleSubmit = async () => {
    try {
      const assignShiftData = {
        EmployeeIds: selectedEmployees.map(emp => emp.id),
        ShiftId: Number(shiftData.shiftName), // Convert to number
        StartDate: shiftData.startDate, // Pass start date from form
        EndDate: shiftData.endDate || null, // Pass end date or null
        Notes: shiftData.reason || "", // Pass notes/reason or empty string
        UpdateAttendance: updateAttendance, // Include the flag for updating attendance
      };

      const result = await assignShiftToEmployees(assignShiftData);
      if (result) {
        showToast("success", "Shift assigned successfully");
        handleCloseModal();
      } else {
        showToast("error", "Failed to assign shift.");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  return (
    <ModalComponent
      show={isShiftModalOpen}
      size="xl"
      bodyClassName="gap-4 mt-2"
      heading="Assign Shift"
      showFooter={false}
      onClose={handleCloseModal}>
      <div className="flex flex-col gap-4">
        {/* Employee Selection */}
        <div>
          <label htmlFor="employee" className="block text-sm font-medium text-gray-700">
            Employee<span className="text-red-500">*</span>
          </label>
          <select
            id="employee"
            name="employee"
            onChange={handleUserInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
            <option value="">Select</option>
            {employees.map(employee => (
              <option key={employee.id} value={employee.id}>
                {`${employee.firstName} ${employee.lastName}`}
              </option>
            ))}
          </select>
        </div>

        {/* Shift Name */}
        <div>
          <label htmlFor="shiftName" className="block text-sm font-medium text-gray-700">
            Shift Name
          </label>
          <select
            id="shiftName"
            name="shiftName"
            onChange={handleInputChange}
            onFocus={fetchShifts}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
            <option value="">Select Shift</option>
            {shifts.length > 0 ? (
              shifts.map(shift => (
                <option key={shift.id} value={shift.id}>
                  {shift.shiftName}
                </option>
              ))
            ) : (
              <option disabled>No Shifts Available</option>
            )}
          </select>
        </div>

        {/* Date Range */}
        <div className="flex gap-4">
          <div>
            <label htmlFor="startDate" className="block text-sm font-medium text-gray-700">
              Start Date<span className="text-red-500">*</span>
            </label>
            <input
              type="date"
              id="startDate"
              name="startDate"
              onChange={handleDateChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div>
            <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
              End Date
            </label>
            <input
              type="date"
              id="endDate"
              name="endDate"
              min={shiftData.startDate}
              disabled={!shiftData.startDate} // Disable if Start Date is not selected
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>

        {/* Reason Input */}
        <div>
          <label htmlFor="reason" className="block text-sm font-medium text-gray-700">
            Reason
          </label>
          <textarea
            id="reason"
            name="reason"
            rows="4"
            onChange={handleInputChange}
            placeholder="Reason"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
        </div>

        {/* Update Past Days Checkbox */}
        {isPastDate && (
          <div className="mt-4 p-4 bg-yellow-50 border-l-4 border-yellow-400 rounded shadow-sm">
            <h2 className="text-sm font-semibold text-yellow-800 mb-2">
              Update past days' attendance entries
            </h2>
            <p className="text-sm text-gray-700 mb-4">
              You have selected past dates. By enabling the checkbox below, settings of the selected
              shift will overwrite settings of the previous shift including overtime,
              present/absence status, payable hours, grace period policy, compensatory off credit,
              and absent schedule.
            </p>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="updateAttendance"
                checked={updateAttendance}
                onChange={handleCheckboxChange}
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="updateAttendance" className="ml-2 text-sm text-gray-700">
                Update past days' attendance entries
              </label>
            </div>
          </div>
        )}

        {/* Submit Button */}
        <ModalButtons
          btnCont="flex justify-end gap-2"
          cancelBtnText="Cancel"
          actionBtnText="Submit"
          onClickCancel={handleCloseModal}
          onClickAction={handleSubmit}
          actionBtnType="button"
          actionBtnClass={`${btnPrimary()} rounded-md text-white font-semibold  px-4 py-[10px] tracking-wide`}
          cancelBtnClass="gray"
        />
      </div>
    </ModalComponent>
  );
};

export default AssignShiftModal;
