import React from "react";

/**
 * Props for the TextArea component.
 */
interface TextAreaProps {
  id: string;
  name: string;
  label: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder: string;
  styles: {
    textAreaContainer: string;
    textAreaLabelClassName: string;
    textAreaInputClassName: string;
  };
}

/**
 * Renders a TextArea component with the specified properties.
 *
 * @param {TextAreaProps} props - The properties for the TextArea component
 * @return {JSX.Element} The rendered TextArea component
 */
const TextArea: React.FC<TextAreaProps> = ({
  id,
  name,
  label,
  value,
  onChange,
  placeholder,
  styles,
}: TextAreaProps): JSX.Element => {
  return (
    <div className={styles.textAreaContainer}>
      <label
        htmlFor={id}
        className={`${styles.textAreaLabelClassName} block mb-2 text-sm font-medium text-gray-900 dark:text-white`}>
        {label}
      </label>
      <textarea
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-thynkwebPrimary-600 focus:border-thynkwebPrimary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-thynkwebPrimary-500 dark:focus:border-thynkwebPrimary-500 ${styles.textAreaInputClassName}`}
      />
    </div>
  );
};

export default TextArea;
