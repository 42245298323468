import React, { ChangeEvent } from "react";
import { INPUT_FIELDS_CLASS } from "../../../constants/Constant";
import { capitalizeFirstLetter, checkNullUndefined } from "../helper";
import MandatorySign from "./MandatorySign";
import InfoIconSvg from "../../../media/InfoIconSvg";

interface InputFieldProps {
  inputArr?: Array<{
    id: string;
    label: string;
    type: string;
    name: string;
    placeholder: string;
    required: boolean;
    helperText?: string;
    minDate?: string;
    otherProps?: React.InputHTMLAttributes<HTMLInputElement>;
  }>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  inputClassName?: string;
  labelClassName?: string;
  parentClassName?: string;
  InputField?: {
    id: string;
    label: string;
    type: string;
    name: string;
    placeholder: string;
    required: boolean;
  };
  value?: string;
  icon?: React.ReactNode;
  otherProps?: React.InputHTMLAttributes<HTMLInputElement>;
  dir?: string;
  helperText?: string;
  disabled?: boolean;
  minDate?: string;
}

/**
 * Renders an input field or a collection of input fields based on the provided props.
 *
 * @param {InputFieldProps} props - The properties for the input field component.
 * @param {Array} [props.inputArr] - An optional array of input field configurations.
 * @param {Function} props.onChange - The event handler for input changes.
 * @param {string} [props.inputClassName] - An optional class name for the input element.
 * @param {string} [props.labelClassName] - An optional class name for the label element.
 * @param {string} [props.parentClassName] - An optional class name for the parent element.
 * @param {Object} [props.InputField] - An optional single input field configuration.
 * @param {string} [props.value] - The value of the input field.
 * @param {React.ReactNode} [props.icon] - An optional icon to display with the input.
 * @param {React.InputHTMLAttributes<HTMLInputElement>} [props.otherProps] - Additional attributes for the input element.
 * @param {string} [props.dir] - The text direction for the input field.
 * @param {string} [props.helperText] - An optional helper text to display below the input field.
 * @param {boolean} [props.disabled] - Whether the input field is disabled.
 * @param {string} [props.minDate] - The minimum date value for date input fields.
 * @returns {JSX.Element} The rendered input field(s).
 */
const InputField: React.FC<InputFieldProps> = ({
  inputArr,
  onChange,
  inputClassName,
  labelClassName,
  parentClassName,
  InputField,
  value,
  icon,
  otherProps,
  dir,
  helperText,
  disabled,
  minDate,
}: InputFieldProps): JSX.Element => {
  if (!inputArr || inputArr.length === 0) {
    return (
      <div className={icon ? `${parentClassName} tc-input-with-icon` : `${parentClassName} tc-input`}>
        {InputField?.label && (
          <label
            htmlFor={InputField.id}
            className={`${labelClassName} block mb-2 text-sm font-medium text-gray-900 dark:text-white cursor-pointer`}>
            {capitalizeFirstLetter(InputField.label)}
            {InputField.required && <MandatorySign />}
          </label>
        )}
        {icon}
        <input
          type={InputField?.type}
          name={InputField?.name}
          id={InputField?.id}
          onChange={onChange}
          autoComplete="off"
          className={inputClassName ? INPUT_FIELDS_CLASS(inputClassName) : INPUT_FIELDS_CLASS()}
          placeholder={checkNullUndefined(InputField?.placeholder)}
          required={InputField?.required}
          value={value ?? ""}
          disabled={disabled}
          {...otherProps}
          min={minDate}
          dir={dir}
        />
        {helperText && (
          <span className="text-sm text-gray-500 flex items-start gap-2 mt-2">
            <InfoIconSvg className="w-4 h-4 fill-current mt-[.2rem]" />
            {helperText}
          </span>
        )}
      </div>
    );
  }

  // Multiple input fields
  const InputFieldsArr = inputArr.map(input => (
    <div
      key={input.id}
      className={`${input.id === "company-name" ? "col-span-2" : "w-full"} ${parentClassName}`}>
      {input.label && (
        <label
          htmlFor={input.id}
          className={`${labelClassName} block mb-2 text-sm font-medium text-gray-900 dark:text-white`}>
          {capitalizeFirstLetter(input.label)}
          {input.required && <MandatorySign />}
        </label>
      )}
      <input
        type={input.type}
        name={input.name}
        id={input.id}
        onChange={onChange}
        className={inputClassName ? INPUT_FIELDS_CLASS(inputClassName) : INPUT_FIELDS_CLASS()}
        placeholder={checkNullUndefined(input.placeholder)}
        required={input.required}
        min={input.minDate}
        autoComplete="off"
        {...input.otherProps}
        dir={dir}
      />
      {input.helperText && (
        <span className="text-sm text-gray-500 flex items-start gap-2 mt-2">
          <InfoIconSvg className="w-4 h-4 fill-current" />
          {input.helperText}
        </span>
      )}
    </div>
  ));

  return <>{InputFieldsArr}</>;
};

const InputFields = React.memo(InputField);

export default InputFields;




















