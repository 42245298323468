import React, { useState, useEffect, useContext } from "react";
import {
  GetDepartments,
  UpdateDepartment,
  DeleteDepartment,
  CreateDepartment,
  GetDepartmentDetails,
} from "../../services/DepartmentService";
import { Button } from "flowbite-react";
import { Pagination } from "flowbite-react";
import AddEditDepartment from "./addEditDepartment";
import ModalComponent from "../common/Modal/Modal";
import { FaRegTrashAlt } from "react-icons/fa";

// Import custom table components
import Table from "../common/Table/Table"; // Update the import path

import Toggle from "../common/Toggle/Toggle";

const Department = () => {
  //Pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default page size

  const [departments, setDepartments] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Columns for the table
  const DepartmentTableHeaders = [
    "Name",
    "Code",
    "Description",
    "Department Head",
    "Parent Department",
    "Assigned Users",
    "Status",
    "Actions",
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const handleModalSubmit = async formData => {
    try {
      if (formData.id) {
        // Update department
        await UpdateDepartment(formData.id, formData);
      } else {
        await CreateDepartment(formData);
      }
      closeModal();
      fetchDepartments();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  useEffect(() => {
    fetchDepartments();
  }, [pageNo, pageSize]);

  const fetchDepartments = async () => {
    try {
      const response = await GetDepartments(
        false,
        pageNo,
        pageSize
      );

      setDepartments(response.data);
      setTotalRecords(response.totalRecords);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const openModal = (department = null) => {
    setSelectedDepartment(department);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setSelectedDepartment(null);
    setIsModalVisible(false);
  };

  const deleteDepartment = async department => {
    try {
  
      // Call delete API
      const deleteResponse = await DeleteDepartment(department.id);

      // Immediately update the departments list
      setDepartments(prevDepartments => prevDepartments.filter(dept => dept.id !== department.id));

      // Refresh departments list
      await fetchDepartments();
    } catch (error) {
      // More detailed error logging
      console.error("Error deleting department:", error.response?.data || error.message);
    }
  };

  const openAssignedUsersModal = async (departmentId) => {
    try {
      const response = await GetDepartmentDetails(departmentId);
      setModalData({
        ...response,
        employees: response.employees || [],
      });
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching department details:", error);
    }
  };

  const closeEmployeeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  //resuing table:
  const toggleStatus = async department => {
    try {
      const updatedDepartment = {
        ...department,
        status: department.status === 1 ? 0 : 1,
      };
      await UpdateDepartment(updatedDepartment.id, updatedDepartment);
      fetchDepartments();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  // Prepare table rows data
  const renderStatusToggle = department => (
    <Toggle
      checked={department.status === 1}
      onChange={e => {
        e.stopPropagation(); // Prevent bubbling to the row click event
        toggleStatus(department);
      }}
      name={`department-status-${department.id}`}
      id={`department-status-${department.id}`}
    />
  );
  const tableRows = departments.map(department => [
    department,
    [
      department.name,
      department.code,
      <div className="truncate w-32" title={department.description}>
        {department.description}
      </div>,
      department.departmentHeadId ? department.departmentHeadName : "-", // Placeholder for department head
      department.parentDepartmentId ? department.parentDepartmentName : "-", // Placeholder for parent department
      <span
        onClick={e => {
          e.stopPropagation(); // Prevent bubbling to edit row event
          openAssignedUsersModal(department.id);
        }}
        className="cursor-pointer text-blue-600">
        {department.totalEmployees || `-`}
      </span>,
      renderStatusToggle(department),
      <>
        <button
          onClick={e => {
            e.stopPropagation(); // Prevents event bubbling to parent elements
            deleteDepartment(department);
          }}
          className="text-gray-400 hover:text-red-500 ml-5">
          <FaRegTrashAlt className="h-4 w-4" />
        </button>
      </>,
    ],
  ]);

  const handleDepartmentEdit = e => {
    // Check if the clicked element is inside a non-interactive element (like a button, toggle, etc)
    const clickedElement = e.target;

    // Stop event propagation if clicked element is a button, toggle or span (these are interactive elements inside rows)
    if (
      clickedElement.closest("button") ||
      clickedElement.closest("span") ||
      clickedElement.closest("input[type='checkbox']") || // avoid on input or toggle
      clickedElement.closest("label") // For labels around the toggles
    ) {
      return; // Do nothing and avoid handling row click
    }
    // Find the closest `tr` element
    const trElement = clickedElement.closest("tr");

    if (trElement) {
      const department = JSON.parse(trElement.dataset.id);
      if (department) {
        openModal(department);
      } else {
        console.warn("Department ID not found!");
      }
    }
  };

  return (
    <div className="p-6 bg-gray-100 w-[90vw] h-[100vh]">
      {isModalOpen && modalData && (
        <ModalComponent
          show={isModalOpen}
          onClose={closeEmployeeModal}
          heading={modalData.name}
          body={
            <>
              {/* Summary */}
              <p className="text-sm sm:text-base text-gray-700 dark:text-gray-300 mb-4">
                <strong>Total Employees:</strong> {modalData.totalEmployees || 0}
              </p>

              {/* Employee List */}
              {modalData.employees && modalData.employees.length > 0 ? (
                <Table
                  col={["Id", "Name", "Email"]}
                  rows={modalData.employees.map((employee, index) => [
                    employee.id,
                    [`${index + 1}`, `${employee.firstName} ${employee.lastName}`, employee.email],
                  ])}
                />
              ) : (
                <p className="text-gray-600 dark:text-gray-300">No employees found.</p>
              )}
            </>
          }
        />
      )}

      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-100">
          Department Management
        </h2>
        <Button
          icon="pi pi-plus"
          className=" top-[-10px] right-[0] bg-thynkwebPrimary-800 hover:bg-thynkwebPrimary-900 focus:ring-4 focus:ring-thynkwebPrimary-300 dark:focus:ring-thynkwebPrimary-900 mt-4"
          onClick={() => openModal()}>
          Add Department
        </Button>
      </div>

      <AddEditDepartment
        visible={isModalVisible}
        onCancel={closeModal}
        onSave={handleModalSubmit}
        showModal={isModalVisible}
        onCloseModal={closeModal}
        department={selectedDepartment}
      />
      <Table col={DepartmentTableHeaders} rows={tableRows} tableClick={handleDepartmentEdit} />
      {/* Flowbite Pagination */}
      <div className="flex justify-end my-4">
        <Pagination
          currentPage={pageNo}
          onPageChange={page => setPageNo(page)}
          showIcons={true}
          totalPages={totalRecords === undefined ? 1 : Math.ceil(totalRecords / pageSize)}
        />
      </div>
    </div>
  );
};

export default Department;
