import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Label, Button } from "flowbite-react";

import SelectWithDropdown from "../../components/common/SelectWithDropdown/SelectWithDropdown";
import SelectComponent from "../../components/Settings/controlPanelSettings/SelectComponent/SelectComponent";
import WeekDayOffSelect from "../../components/Settings/controlPanelSettings/SelectComponent/WeekDayOffSelect";
import { PayPeriodType } from "../../enum/PayPeriodType";
import { PayDayType } from "../../enum/PayDayType";
import { useStore } from "../../context/createFastContext";
import { showToast } from "../../components/common/Toast";
import MandatorySign from "../../components/common/Input/MandatorySign";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { GrCircleInformation } from "react-icons/gr";
import {
  PAY_DAYS,
  PayDayOptions,
  PayPeriodOptions,
  WORKING_HOURS,
} from "../../components/Settings/helper/helper";
import { getSettings, updateSettings } from "../../services/CommonService";
import { ToolTipContent, fields, getLast12Months } from "./helper";
import { btnPrimary } from "../../utils/helper";

const Settings = ({}) => {
  const [multipleFormsState, setStore] = useStore(s => s.multipleFormsState);
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([
    {
      workingHours: "",
      payStartDay: PAY_DAYS[0] + 1,
      payEndDay: PAY_DAYS[0],
      weekDaysOff: [],
      payDay: "",
    },
  ]);

  const [state, setState] = useState({
    payPeriodTypeOptions: PayPeriodOptions,
    selectedPayPeriodType: PayPeriodOptions[0],
    payDayOptions: PayDayOptions,
    selectedPayDay: PayDayOptions[0],
    selectedPayrollOptions: getLast12Months()[0],
  });

  useEffect(() => {
    getSettings().then(res => {
      const { data } = res?.data;
      data.payEndDay = data?.payEndDay === 0 ? PAY_DAYS[21] : data?.payEndDay;
      data.weekDaysOff = data?.weekDaysOff === null ? ["0", "6"] : data?.weekDaysOff.split(",");
      data.payDay = data?.payDay;
      setSelectedOptions([data]);
    });
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    let isEmpty;
    const { payEndDay, weekDaysOff, payDay } = selectedOptions[0];
    const bodyData = {
      ...selectedOptions[0],
      payStartDay: +payEndDay + 1,
      payPeriodType: state.selectedPayPeriodType.value,
      payDayType: state.selectedPayDay.value,
      payDay: state.selectedPayDay.value === PayDayType.LastWorkingDay ? 0 : payDay,
      weekDaysOff: weekDaysOff.toString(),
    };
    bodyData.lastPayrollRunMonth = state.selectedPayrollOptions.month;
    bodyData.lastPayrollYear = state.selectedPayrollOptions.year
    if (state.selectedPayPeriodType.value === PayPeriodType.DaysInMonth) {
      delete bodyData.payEndDay;
      delete bodyData.payStartDay;
    }

    if (bodyData.payPeriodType === PayPeriodType.FixedRange) {
      isEmpty =
        bodyData.payStartDay !== 0 &&
        bodyData.payEndDay !== 0 &&
        bodyData.payDay !== "" &&
        bodyData.workingHours !== "" &&
        bodyData.weekDaysOff !== "";   
    } else {
      isEmpty =
        bodyData.payDay !== "" && bodyData.workingHours !== "" && bodyData.weekDaysOff !== "";
    }

    if (!isEmpty) return showToast("warn", "All fields marked with * must be filled.");
    updateSettings(bodyData).then(res => {
      if (res.data.statusCode === 200) {
        if (multipleFormsState.activeStep === multipleFormsState.step.length - 1) {
          showToast("success", "Settings added successfully");
          navigate("/");
          return;
        }
        setStore({
          multipleFormsState: {
            ...multipleFormsState,
            reFetchStatus: !multipleFormsState.reFetchStatus,
            activeStep:
              multipleFormsState.activeStep < multipleFormsState.step.length
                ? multipleFormsState.activeStep + 1
                : multipleFormsState.activeStep,
            steps: multipleFormsState.steps.map((step, i) => {
              if (i === multipleFormsState.activeStep + 1) {
                return {
                  ...step,
                  color: "blue",
                };
              }
              return step;
            }),
          },
        });
      }
    });
  };

  const onChangeHandler = e => {
    const { name, value } = e.target;

    if (name === "workingHours" && (+value > 14 || +value < 6))
      return showToast("warn", "Working hours should be greater than 6 and less than 15");

    setSelectedOptions(prevSO => {
      return [
        {
          ...prevSO[0],
          [name]: +value,
          payStartDay: Number(value) + 1,
        },
      ];
    });
  };

  const handleOptionsChange = (_, selectedOption) => {
    setSelectedOptions(s => [{ ...s[0], payPeriodType: selectedOption.value }]);
    setState(s => ({
      ...s,
      selectedPayPeriodType: selectedOption,
    }));
  };

  const handlePayDayOptionsChange = (_, selectedOption) => {
    setState(s => ({
      ...s,
      selectedPayDay: selectedOption,
    }));

    //Set payDay zero if last working day is selected else set payDay 1
    setSelectedOptions(prevSO => {
      return [
        {
          ...prevSO[0],
          payDay: selectedOption.value === 0 ? 0 : 1,
        },
      ];
    });
  };

  const handlePayDay = e => {
    const { name, value } = e.target;
    setSelectedOptions(prevSO => {
      return [
        {
          ...prevSO[0],
          [name]: +value,
        },
      ];
    });
  };

  const handlePayrollOptions = (_, selectedOption) => {
    const selectedDateArr = selectedOption.label.split("-");
    const currentDate = new Date().getMonth();
    const selectedDate = new Date(`${selectedDateArr[0]} ${selectedDateArr[1]}`).getMonth();

    if (selectedDate > currentDate) return showToast("warn", "Cannot select future Months!");

    setState(s => ({
      ...s,
      selectedPayrollOptions: selectedOption,
    }));
  };

  const onClickBackHandler = () => {
    setStore({
      multipleFormsState: {
        ...multipleFormsState,
        activeStep: 0, //current tab
        steps: multipleFormsState.steps.map((step, i) => {
          if (i === 0) {
            return {
              ...step,
              color: "blue",
            };
          }
          return {
            ...step,
            color: "gray",
          };
        }),
      },
    });
  };

  return (
    <form
      className="grid grid-cols-2 items-center justify-between mt-5 gap-x-[3rem] gap-y-6 ml-4 mr-4"
      onSubmit={handleSubmit}>
      {/* working hours */}
      <SelectComponent
        name="Working Hours"
        onChangeHandler={onChangeHandler}
        optionsArr={WORKING_HOURS}
        defaultValue={selectedOptions[0].workingHours}
        nameAttr="workingHours"
        parentClass="self-start"
        required={true}
        isMandatory={true}
        placeholder={selectedOptions[0].workingHours || "Select Working Hours"}
        showPlaceholder={true}
      />

      {/* Pay Period Type */}
      <div>
        <div className="flex self-start items-center">
          <Label className={`text-gray-600 font-medium self-start capitalize`}>
            Select pay period
          </Label>
          <MandatorySign className="-mt-2" />
          <div
            className="ml-2 -mt-1"
            data-tooltip-id="payPeriod"
            data-tooltip-html={ToolTipContent.payPeriod}
            data-tooltip-place="bottom"
            data-tooltip-content={ToolTipContent.payPeriod}>
            <GrCircleInformation />
          </div>

          <ReactTooltip id="payPeriod" />
        </div>
        <div className="mt-[4px] tc-dropdown">
          <SelectWithDropdown
            roundedDot={false}
            size="sm"
            dotClassName=""
            options={state.payPeriodTypeOptions}
            defaultValue={state.selectedPayPeriodType?.label ?? "Select pay period"}
            handleChange={handleOptionsChange}
            className="-pl-4 w-[14.2rem]"
            backgroundColor="bg-gray-100 w-[14.2rem] h-[2.5rem] rounded-md"
            isDefaultValue
          />
        </div>
      </div>

      <div>
        <div className="flex items-center">
          <Label className="text-gray-600 font-medium self-start capitalize">
            Last payroll run
          </Label>
          <MandatorySign className="-mt-2" />

          <div
            className="ml-2 -mt-1"
            data-tooltip-id="lastPayRollRun"
            data-tooltip-place="bottom"
            data-tooltip-html={ToolTipContent.lastPayRollRun}
            data-tooltip-content={ToolTipContent.lastPayRollRun}>
            <GrCircleInformation />
          </div>

          <ReactTooltip id="lastPayRollRun" />
        </div>
        <div className="mt-[4px] tc-dropdown">
          <SelectWithDropdown
            roundedDot={false}
            size="sm"
            dotClassName=""
            options={getLast12Months()}
            defaultValue={state.selectedPayrollOptions?.label ?? "Select last payroll run"}
            handleChange={handlePayrollOptions}
            className="-pl-4 w-[14.2rem]"
            itemsContainer="h-[10rem] overflow-y-auto"
            backgroundColor="bg-gray-100 w-[14.2rem] h-[2.5rem] rounded-md"
            isDefaultValue
          />
        </div>
      </div>

      {/* Pay Start Day */}
      {state.selectedPayPeriodType.value === PayPeriodType.FixedRange && (
        <>
          <SelectComponent
            name="Pay Start Day"
            optionsArr={PAY_DAYS}
            defaultValue={Number(selectedOptions[0].payEndDay) + 1}
            nameAttr="payStartDay"
            isMandatory={true}
            disabled={true}
            showPlaceholder={false}
          />
          {/* Pay End Day */}
          <SelectComponent
            name="Pay End Day"
            onChangeHandler={onChangeHandler}
            optionsArr={PAY_DAYS}
            defaultValue={selectedOptions[0].payEndDay}
            nameAttr="payEndDay"
            isMandatory={true}
            showPlaceholder={false}
            disabled={
              state.selectedPayPeriodType.value === PayPeriodType.DaysInMonth ? true : false
            }
          />
        </>
      )}

      {/* Pay Day Type */}
      <div>
        <div>
          <Label className={`text-gray-600 font-medium self-start capitalize mb-[10px]`}>
            Select pay day
          </Label>
          <MandatorySign />
        </div>
        <div className="mt-[4px] tc-dropdown">
          <SelectWithDropdown
            roundedDot={false}
            size="sm"
            dotClassName=""
            options={state.payDayOptions}
            defaultValue={state.selectedPayDay?.label ?? "Select Pay Day Type"}
            handleChange={handlePayDayOptionsChange}
            className="-pl-4 w-[14.2rem]"
            backgroundColor="bg-gray-100 w-[14.2rem] h-[2.5rem] rounded-md"
            isDefaultValue
          />
        </div>
      </div>

      {/* Pay Day */}
      {state.selectedPayDay.value === PayDayType.DayOfMonth && (
        <SelectComponent
          name="Pay Day"
          onChangeHandler={handlePayDay}
          optionsArr={PAY_DAYS}
          defaultValue={Number(selectedOptions[0].payDay)}
          nameAttr="payDay"
          placeholder={selectedOptions[0].payDay || "Select Pay Day"}
          showPlaceholder={true}
          isMandatory={true}
        />
      )}

      <div className="flex items-center justify-between mt-3 col-span-full">
        <Button
          className={`${btnPrimary()} rounded-3xl w-fit px-5 py-1`}
          onClick={onClickBackHandler}
          type="button">
          Back
        </Button>
        <Button type="submit" className={`${btnPrimary()} rounded-3xl w-fit px-5 py-1`}>
          Finish
        </Button>
      </div>
    </form>
  );
};

export default Settings;

export const settingsState = {
  inputFields: fields.reduce((acc, curr) => {
    acc[curr.id] = "";
    return acc;
  }, {}),
  fetchSettings: {},
};
