import React, { useState, useEffect, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { useAppSelector } from "../../store/store";
import { AuthContext } from "../../context/AuthContext";
import ModalComponent from "../common/Modal/Modal";
import ModalButtons from "../common/Modal/ModalButtons";
import { btnPrimary } from "../../utils/helper";
import DepartmentDropdown from "../common/DropDown/DepartmentDropdown";
import { GetUsers } from "../../services/UserService";

const AddEditDepartment = ({ department, onSave, onCancel, showModal, onCloseModal }) => {
  const user = useAppSelector(state => state.userManagement.user);
  const { user: authUser } = useContext(AuthContext);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedDepartmentHead, setSelectedDepartmentHead] = useState("");
  const [users, setUsers] = useState([]); // State to store users


  
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    description: "",
    status: "",
    createdBy: "",
    updatedBy: "",
    departmentHeadId: null,
    parentDepartmentId: null,
  });
  // Fetch users when component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await GetUsers(100, 1); // Fetch first 100 users
        setUsers(response.data || []);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (department) {
      setFormData({
        ...department,
        status: department.status,
        createdBy: department.createdBy,
        updatedBy: authUser.id,
        departmentHeadId: department.departmentHeadId,
        parentDepartmentId: department.parentDepartmentId,
      });
      setSelectedDepartmentHead(department.departmentHeadId || null);
    } else {
      setFormData({
        name: "",
        code: "",
        description: "",
        status: 1,
        createdBy: authUser.id,
        updatedBy: authUser.id,
        departmentHeadId: null, // Reset department head
        parentDepartmentId: null,
      });
      setSelectedDepartmentHead(null);
    }
  }, [department]);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    try {
      await onSave(formData);

      onCloseModal();
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  // Handle department head selection
  const handleDepartmentHeadChange = e => {
    const selectedUserId = e.target.value ? parseInt(e.target.value, 10) : null; // Convert to integer
    setSelectedDepartmentHead(selectedUserId);
    setFormData(prev => ({ ...prev, departmentHeadId: selectedUserId }));
  };

  const handleDepartmentsChange = departmentIds => {

    // Extract the id value (assuming departmentIds is an array of objects)
    const parentDepartmentId = departmentIds.length > 0 ? departmentIds[0].id : null;

    setSelectedDepartments(departmentIds); // Keep full array if needed for other purposes

    // Update formData with only the id value
    setFormData(prev => ({
      ...prev,
      parentDepartmentId: parentDepartmentId,
    }));

  };

  return (
    <ModalComponent
      show={showModal}
      onClose={onCloseModal}
      heading={department ? "Edit Department" : "Add Department"}
      size="md"
      showFooter={false}
      className="custom-modal-class"
      bodyClassName="px-2 py-4 bg-white dark:bg-gray-900 rounded-lg shadow-lg">
      <div className="max-w-md mx-auto p-6 bg-white dark:bg-gray-900 rounded-lg ">
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Name
          </label>
          <InputText
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md dark:bg-gray-800 dark:text-white dark:border-gray-700 focus:ring-2 focus:ring-indigo-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="code"
            className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Code
          </label>
          <InputText
            id="code"
            name="code"
            value={formData.code}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md dark:bg-gray-800 dark:text-white dark:border-gray-700 focus:ring-2 focus:ring-indigo-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="description"
            className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Description
          </label>
          <InputText
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md dark:bg-gray-800 dark:text-white dark:border-gray-700 focus:ring-2 focus:ring-indigo-500"
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="departmentHead"
            className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Department Head
          </label>
          <select
            id="departmentHead"
            name="departmentHeadId"
            value={selectedDepartmentHead}
            onChange={handleDepartmentHeadChange}
            className="w-full px-3 py-3  rounded-md dark:bg-gray-800 dark:text-white border-gray-200 focus:ring-2 focus:ring-indigo-500">
            <option value="">Select Department Head</option>
            {users.map(user => (
              <option key={user.id} value={user.id}>
                {`${user.firstName} ${user.lastName}`}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="parentDepartment"
            className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Parent Department
          </label>
          <div className="w-full">
            <DepartmentDropdown
              selectedDepartments={selectedDepartments}
              onDepartmentsChange={handleDepartmentsChange}
              multiSelect={false}
              className="w-full" // Ensure the dropdown itself takes the full width
            />
          </div>
        </div>
        {/* Submit Buttons */}
        <div className="flex justify-end gap-3 mt-6">
          <ModalButtons
            btnCont="flex justify-end gap-2"
            cancelBtnText="Cancel"
            actionBtnText={department ? "Update" : "Submit"}
            onClickCancel={onCancel}
            onClickAction={handleSubmit}
            actionBtnType="button"
            actionBtnClass={`${btnPrimary()} rounded-md text-white font-semibold  px-4 py-[10px] tracking-wide`}
            cancelBtnClass="gray"
          />
        </div>
      </div>
    </ModalComponent>
  );
};

export default AddEditDepartment;
