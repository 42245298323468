import { showToast } from "../components/common/Toast";
import { axiosInstance } from "../context/AuthContext";

const BaseUrl = process.env.REACT_APP_BASE_URL;
const departmentBaseUrl = `${BaseUrl}/Departments`;
// Create a new department
export const CreateDepartment = async department => {
  try {
    const response = await axiosInstance({
      url: departmentBaseUrl,
      method: "POST",
      data: JSON.stringify(department),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update an existing department
export const UpdateDepartment = async (id, department) => {
  try {
    const response = await axiosInstance({
      url: `${departmentBaseUrl}/${id}`,
      method: "PUT",
      data: JSON.stringify(department),
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Delete a department
export const DeleteDepartment = async id => {
  try {
    await axiosInstance({
      url: `${departmentBaseUrl}/${id}`,
      method: "DELETE",
    });
  } catch (error) {
    throw error;
  }
};

// Fetch all departments
export const GetDepartments = async ( isActive, page = 1, pageSize = 10) => {
  // set isActive true for showing departments in dropdown during employee creation
  try {
    const response = await axiosInstance.get(
      `${departmentBaseUrl}?isActive=${isActive}&page=${page}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch department details
export const GetDepartmentDetails = async (identifier, page = 1, pageSize = 10) => {
  try {
    const response = await axiosInstance.get(
      `${departmentBaseUrl}/${identifier}/?page=${page}&pageSize=${pageSize}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
