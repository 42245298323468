import React, { useState, useEffect, useContext, useRef } from "react";
import { GetDepartments } from "../../../services/DepartmentService";
import { AuthContext } from "../../../context/AuthContext";
import AddSvg from "../../../media/AddSvg";
import CrossIconSvg from "../../../media/CrossIconSvg";

export default function DepartmentDropdown({
  selectedDepartments,
  onDepartmentsChange,
  multiSelect = true,
}) {
  const [departments, setDepartments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { user: authUser } = useContext(AuthContext);
  const dropdownRef = useRef(null);

  // Emit dropdown open state as a custom event
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    window.dispatchEvent(new CustomEvent("dropdown-toggle", { detail: dropdownRef.current }));
  };

  // Close dropdown on outside click
  useEffect(() => {
    const handleOutsideClick = e => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);
    return () => window.removeEventListener("click", handleOutsideClick);
  }, []);

  // Fetch departments on dropdown open
  useEffect(() => {
    if (isOpen) {
      fetchDepartments();
    }
  }, [isOpen]);

  const fetchDepartments = async () => {
    setIsLoading(true);
    try {
      const response = await GetDepartments(true, 1, 100);

      // Pre-select departments that are already in selectedDepartments
      const updatedDepartments = response.data.map(dept => ({
        ...dept,
        isSelected: selectedDepartments.some(sd => sd.id === dept.id),
      }));

      setDepartments(updatedDepartments);
    } catch (error) {
      console.error("Error fetching departments:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDepartmentToggle = department => {
    if (multiSelect) {
      // Multi-select logic
      const isAlreadySelected = selectedDepartments.some(sd => sd.id === department.id);
      const updatedSelection = isAlreadySelected
        ? selectedDepartments.filter(sd => sd.id !== department.id)
        : [...selectedDepartments, department];

      onDepartmentsChange(updatedSelection);
    } else {
      // Single-select logic
      onDepartmentsChange([department]);
      setIsOpen(false);
    }
  };

  const handleClearSelection = () => {
    onDepartmentsChange([]);
  };

  const getSelectedDepartmentNames = () => {
    return selectedDepartments.map(dept => dept.name).join(", ");
  };

  return (
    <div className="flex items-center gap-2 relative" ref={dropdownRef}>
      <div className="w-full">
        <div
          className="block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm cursor-pointer p-2 flex items-center"
          onClick={toggleDropdown}>
          {/* Dropdown Label */}
          <span>
            {selectedDepartments.length === 0 ? "Select Department" : getSelectedDepartmentNames()}
          </span>

          {/* Action Button (Cross or Dropdown Icon) */}
          <button
            type="button"
            className="text-gray-500 hover:text-blue-600 ml-auto"
            onClick={e => {
              e.stopPropagation(); // Prevent triggering toggleDropdown
              toggleDropdown();
              if (selectedDepartments.length > 0) {
                handleClearSelection();
              }
            }}>
            {selectedDepartments.length > 0 ? (
              // Cross icon for clearing selections
              <CrossIconSvg />
            ) : (
              // Dropdown icon
              <AddSvg size={24} />

              // <svg
              //   xmlns="http://www.w3.org/2000/svg"
              //   className="h-5 w-5"
              //   fill="none"
              //   viewBox="0 0 24 24"
              //   stroke="currentColor">
              //   <path
              //     strokeLinecap="round"
              //     strokeLinejoin="round"
              //     strokeWidth={2}
              //     d="M19 9l-7 7-7-7"
              //   />
              // </svg>
            )}
          </button>
        </div>

        {isOpen && (
          <ul className="absolute z-10 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
            {isLoading ? (
              <li className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                Loading...
              </li>
            ) : (
              departments.map(department => (
                <li
                  key={department.id}
                  className={`cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-indigo-100 ${
                    selectedDepartments.some(sd => sd.id === department.id) ? "bg-indigo-200" : ""
                  }`}
                  onClick={() => handleDepartmentToggle(department)}>
                  <span className="block truncate">{department.name}</span>
                  {multiSelect && selectedDepartments.some(sd => sd.id === department.id) && (
                    <span className="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                      ✔
                    </span>
                  )}
                </li>
              ))
            )}
          </ul>
        )}
      </div>
    </div>
  );
}
