import React from "react";

/**
 * Renders a mandatory sign component.
 *
 * @param {{ className?: string }} props - The component props.
 * @param {string} [props.className] - The class name for the component.
 * @return {JSX.Element} The mandatory sign component.
 */
const MandatorySign: React.FC<{ className?: string }> = ({ className = "" }: { className?: string; }): JSX.Element => {
  return <span className={`${className} text-red-700 ml-1`}>*</span>;
};

export default MandatorySign;
